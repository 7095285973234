import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { TariffBase } from "../../../domain/tariff-base";
import {SupplyType, TariffEntity} from "../../../domain/tariff-entity";
import {TariffService} from "../../../services/tariff.service";
import {BillCalcService} from "../../../services/billcalc.service";
import {BillingPeriod} from "../../../domain/billing-period";
import {SimpleCharges} from "../../../domain/simple-charges";

interface ChargesWithBillingPeriod extends SimpleCharges, BillingPeriod {
}

@Component({
  selector: 'app-tariff-billing',
  templateUrl: './tariff-billing.component.html',
  styleUrls: ['./tariff-billing.component.css']
})
export class TariffBillingComponent implements OnInit, OnChanges {
  @Input() tariff: TariffBase;

  loading: boolean;
  tariffDropdownListings: TariffEntity[];
  file: any;
  billingPeriods: Array<BillingPeriod>;
  charges: Array<ChargesWithBillingPeriod>;
  supplyCode: string;
  fileError: boolean;
  runError: boolean;

  constructor(
    private tariffService: TariffService,
    private billCalcService: BillCalcService
  ) { }

  ngOnInit() {
    this.tariffService.getTariffs(SupplyType).subscribe(data => {
        this.tariffDropdownListings = data;
        this.tariffDropdownListings.sort((a, b)  =>
          (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)
        );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.charges = undefined;
  }

  onChooseFile(event: any) {
    this.fileError = undefined;
    this.charges = undefined;
    this.billingPeriods = [];
    this.loading = true;

    this.file = event.target.files[0];
    this.billCalcService.getBillingPeriods(this.file).subscribe( data => {
      this.billingPeriods = data;
      this.loading = false;
    }, (error => {
      console.log(error);
      this.fileError = true;
      this.loading = false;
    }))
  }

  onRun() {
    this.runError = undefined;
    this.charges = new Array<ChargesWithBillingPeriod>();
    this.loading = true;

    this.billingPeriods.forEach( bp => {
      const startDate = bp.startDate;
      const stopDate =  bp.endDate;
      const deliveryCode = this.tariff.code;
      const supplyCode = this.supplyCode;
      this.billCalcService.runBillCalc(startDate, stopDate, deliveryCode, supplyCode, this.file).subscribe(data => {
        this.charges.push({
          startDate : startDate,
          endDate : stopDate,
          energy : data.energy,
          demand : data.demand,
          other : data.other,
          total : data.total
        });
        this.charges.sort((a,b) => a.startDate.localeCompare(b.startDate));
        if (this.charges.length == this.billingPeriods.length) {
          this.loading = false;
        }
      }, (error => {
        console.log(error);
        this.runError = true;
        this.loading = false;
      }))
    })
  }

  setTariffSelection(value: any) {
    this.supplyCode = value;
  }
}
