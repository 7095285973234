import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TariffBase} from "../../../domain/tariff-base";
import {TariffService} from "../../../services/tariff.service";
import {SidenavService} from "../../../services/sidenav-service";

@Component({
  selector: 'app-vtl-file-update',
  templateUrl: './vtl-file-update.component.html',
  styleUrls: ['./vtl-file-update.component.css']
})
export class VtlFileUpdateComponent implements OnInit {

  @Input() tariff: TariffBase;
  @Input() tariffType: string;
  fileData: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private service: TariffService,
    private sidenavService: SidenavService
  ) { }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title'
      }).result.then(() => {
        console.log(this.tariffType);
        console.log(`Updating delivery tariff VTL... code=${this.tariff.code}, name=${this.tariff.name}, utilityId=${this.tariff.utilityId}`);
        this.service.importTariffVtl(this.tariffType, this.tariff.code, this.tariff.name, this.tariff.utilityId, this.fileData).subscribe( (t) => {
          this.sidenavService.refreshTariffs(this.tariffType);
          this.fileData = null;
          this.router.navigate([`${this.tariffType.toLowerCase()}/${t.code}`]);
        });
    }, () => {});
  }

  onChooseFile(event: any) {
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);       // UTF-8 is assumed
    reader.onload = () => {
      this.fileData = reader.result.toString();
    };
  }

}
