import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {TimeOfUseRate} from '../../../domain/time-of-use-rate';
import * as moment from 'moment';

const enum WARNING_LEVEL {
  OK,
  WARNING,
  ERROR
}

@Component({
  selector: 'app-date-range-static',
  templateUrl: './date-range-static.component.html'
})
export class DateRangeStaticComponent implements OnInit, OnChanges {
  @Input() rates: TimeOfUseRate[];
  @Input() startDate;
  @Input() endDate;

  private minDate;
  private maxDate;
  private NEXT_30_DAYS;
  private LAST_30_DAYS;
  private LAST_YEAR;

  warninglevel: WARNING_LEVEL;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.NEXT_30_DAYS = moment().add(30, 'days');
    this.LAST_30_DAYS = moment().subtract(30, 'days');
    this.LAST_YEAR = moment().subtract(1, 'years');

    this.warninglevel = WARNING_LEVEL.OK;
    if (this.rates) {
      this.setMinMaxDates(this.rates);
    } else {
      this.minDate = this.startDate;
      this.maxDate = this.endDate;
    }
  }

  private setMinMaxDates(rates: TimeOfUseRate[]) {

    this.minDate = moment();
    this.maxDate = this.LAST_YEAR;
    for (const rate of rates) {
      let touEffectiveEnd = this.LAST_YEAR;
      for (const range of rate.ratePeriods) {
        if (moment(range.startDate).isBefore(this.minDate)) {
          this.minDate = moment(range.startDate);
        }
        if (moment(range.stopDate).isAfter(this.maxDate)) {
          this.maxDate = moment(range.stopDate);
        }
        if (moment(range.stopDate).isAfter(touEffectiveEnd)) {
          touEffectiveEnd = moment(range.stopDate);
        }
      }

      this.checkForWarnings(touEffectiveEnd);
    }
  }

  touErrors() {
    return this.warninglevel === WARNING_LEVEL.ERROR;
  }

  private checkForWarnings(dt: moment.Moment) {
    if (this.touErrors()) {
      return;
    }
    if (dt.isBetween(this.LAST_30_DAYS, moment())) {
      this.warninglevel = WARNING_LEVEL.ERROR;
      return;
    }
    if (dt.isBetween(moment(), this.NEXT_30_DAYS)) {
      this.warninglevel = WARNING_LEVEL.WARNING;
    }
  }
}
