import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-simple-text-prompt',
  templateUrl: './simple-text-prompt.component.html',
  styleUrls: ['./simple-text-prompt.component.css']
})
export class SimpleTextPrompt {
  @Output() textChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() text: string;
  @Input() prompt: string;
  @Input() icon: string;
  @Input() size: string;
  @Input() type: string;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm'
      }).result.then(() => {
      this.textChange.emit(this.text);
    }, () => {});
  }

}
