import {BehaviorSubject, Observable} from "rxjs";
import {SidenavTariff} from "../domain/sidenav-tariff";
import {TariffService} from "./tariff.service";
import {Injectable} from "@angular/core";
import {DeliveryType, SupplyType} from "../domain/tariff-entity";
import {TariffSiteLists} from "../domain/tariff-site-lists";

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private subjectMap: Map<string, BehaviorSubject<Array<SidenavTariff>>> = new Map();
  private subjectTariffSiteMap: Map<string, BehaviorSubject<TariffSiteLists>> = new Map();
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private hideUnused: Map<string, BehaviorSubject<boolean>> = new Map();

  constructor(private tariffService: TariffService) {
    this.subjectMap.set(SupplyType, new BehaviorSubject<Array<SidenavTariff>>([]));
    this.subjectMap.set(DeliveryType, new BehaviorSubject<Array<SidenavTariff>>([]));

    this.subjectTariffSiteMap.set(SupplyType, new BehaviorSubject<TariffSiteLists>({after: [], before: []}));
    this.subjectTariffSiteMap.set(DeliveryType, new BehaviorSubject<TariffSiteLists>({after: [], before: []}));

    this.hideUnused.set(SupplyType, new BehaviorSubject<boolean>(true));
    this.hideUnused.set(DeliveryType, new BehaviorSubject<boolean>(true));
  }

  public refreshTariffs(tariffType: string, hideUnusedTariffs: boolean = true) {
    let tt = this.subjectMap.has(tariffType)
      ? tariffType
      : tariffType.toLowerCase() === DeliveryType.toLowerCase() ? DeliveryType : SupplyType;

    this.loading$.next(true);
    this.hideUnused.get(tt).next(hideUnusedTariffs);

    this.tariffService.getTariffs(tt).subscribe( tariffEntities => {
      this.tariffService.getTariffsSourceControlStatus().subscribe(statuses => {
        const sidenavTariffs: Array<SidenavTariff> = [];
        for (let tariff of tariffEntities) {
          const tariffStatus = statuses.find(ts => ts.tariffCode === tariff.code);
          sidenavTariffs.push({
            code: tariff.code,
            name: tariff.name,
            utilityId: tariff.utilityId,
            utilityName: tariff.utilityName,
            filename: tariffStatus ? tariffStatus.filename : null,
            gitStatus: tariffStatus ? tariffStatus.gitStatus : null
          });
        }

        let tariffs = TariffService.prettify(sidenavTariffs);
        console.log(`${sidenavTariffs.length} ${tt} Tariffs loaded`);

        this.tariffService.getTariffSites(tt).subscribe( tariffSites => {
          if (hideUnusedTariffs) {
            const beforeTariffs = tariffSites.before.map(t => t.code);
            const afterTariffs = tariffSites.after.map(t => t.code);
            const usedTariffs = new Set(beforeTariffs.concat(afterTariffs));
            tariffs = tariffs.filter( t => usedTariffs.has(t.code));
            console.log(`    ${tariffs.length} unused ${tt} Tariffs have been filtered out`);
          } else {
            console.log("    Tariff list is unfiltered.")
          }
          this.subjectMap.get(tt).next( tariffs );
          this.subjectTariffSiteMap.get(tt).next(tariffSites);

          this.loading$.next(false);
        });
      });
    });
  }

  public getTariffs(tariffType: string): Observable<Array<SidenavTariff>> {
    return this.subjectMap.get(tariffType).asObservable();
  }

  public getTariffSites(tariffType: string): Observable<TariffSiteLists> {
    return this.subjectTariffSiteMap.get(tariffType).asObservable();
  }

  public isLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  public getHideUnused(tariffType: string): Observable<boolean> {
    const tt = tariffType.toLowerCase() === DeliveryType.toLowerCase() ? DeliveryType : SupplyType;
    return this.hideUnused.get(tt).asObservable();
  }
}
