import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { DatepickerComponent } from '../datepicker/datepicker.component';
import { TariffService } from '../../../services/tariff.service';
import { RateSeriesGraphBaseComponent } from '../rate-series-graph-base/rate-series-graph.base.component';
import { RateSeries} from '../../../domain/rate-series';
import {Tou} from "../../../domain/tou";
import {TariffBase} from "../../../domain/tariff-base";

@Component({
  selector: 'app-demand-graph',
  templateUrl: './demand-graph.component.html',
  styleUrls: ['./demand-graph.component.css']
})
export class DemandGraphComponent extends RateSeriesGraphBaseComponent implements OnInit, OnChanges {
  @Input() tariff: TariffBase;

  @ViewChild(DatepickerComponent, { static: true }) datePicker;
  @ViewChild('pointStrategy', { static: true }) pointStrategy;
  @ViewChild('includeBillingPeriod', { static: true }) includeBillingPeriod;
  private initializationComplete: boolean;
  rateSeries: Array<RateSeries>;
  loading: boolean;

  constructor(private tariffService: TariffService, modalService: NgbModal) {
    super(modalService);
  }

  ngOnInit(): void {
    this.initTimeWindow();
    this.initializationComplete = true;
    this.repaintGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initializationComplete) {
      this.repaintGraph();
    }
  }

  repaintGraph(): void {
    this.showBillingPeriod();

    this.loading = true;
    const endStamp = this.pointStrategy.nativeElement.checked;
    this.selectedPointRates = [];
    this.tariffService.getDemand(
      this.tariff.code,
      this.startDate.toISOString(),
      this.endDate.toISOString(),
      endStamp,
      this.billingPeriod()
    ).subscribe(data => {
        data.sort((a, b) => {
          if (a.masterId === b.masterId) {
            return a.name.localeCompare(b.name);
          }
          return a.masterId.localeCompare(b.masterId);
        });
        this.rateSeries = data;
        this.loading = false;
        this.buildSeries(this.rateSeries, '$/kW', 'demandChartContainer', endStamp);
      }
    );
  }

  getDatePicker(): DatepickerComponent {
    return this.datePicker;
  }

  getTous(pointDateTime: string): Observable<Array<Tou>> {
    return this.tariffService.getDemandTous(this.tariff.code, pointDateTime);
  }

  disableBillingPeriod(): boolean {
    return moment.duration(this.endDate.diff(this.startDate)).asDays() < 3;
  }

  billingPeriod() {
    let billingPeriod = undefined;
    const checked = this.includeBillingPeriod.nativeElement.checked;
    if (checked) {
      const daysFromStart = moment.duration(this.endDate.diff(this.startDate)).asDays() / 2;
      billingPeriod = moment(this.startDate).add(daysFromStart, 'days').format('YYYY-MM-DD');
    }
    return billingPeriod;
  }

  private showBillingPeriod() {
    const bp = this.billingPeriod();
    if (bp) {
      const bpStartOfDay = moment(bp).utc().startOf('day');
      this.options.xAxis.plotLines = [{
        color: 'red',
        width: 2,
        value: bpStartOfDay.format('x'),
        dashStyle: 'longdashdot',
        label: {
          text: 'New Billing Period',
        }
      }];

    } else {
      this.options.xAxis.plotLines = [];
    }
  }
}
