import {Component, Input, OnInit} from '@angular/core';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'app-admin-control',
  templateUrl: './admin-control.component.html',
  styleUrls: ['./admin-control.component.css']
})
export class AdminControlComponent implements OnInit {

  @Input() staticTariffs: boolean;

  pw: string;

  constructor(public admin: AdminService) { }

  ngOnInit() {
    this.pw = '';
  }

  authenticate(passphrase: string) {
    if (this.pw !== passphrase) {
      if (!this.admin.authenticate(passphrase)) {
        alert('The pass phrase is incorrect.');
      }
    }
  }
}
