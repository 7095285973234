import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { TariffConfigsListComponent } from './tariff-configs-list/tariff-configs-list.component';
import { TariffConfigsViewComponent } from './tariff-configs-view/tariff-configs-view.component';
import { TariffConfigComponent } from './tariff-config/tariff-config.component';
import { RateMappingListComponent } from './rate-mapping-list/rate-mapping-list.component';
import { TariffVersionListComponent } from './tariff-version-list/tariff-version-list.component';
import { MasterTariffIdInputComponent } from './master-tariff-id-input/master-tariff-id-input.component';
import { TariffConfigFileImportComponent } from './tariff-config-file-import/tariff-config-file-import.component';

import { SharedModule } from '../shared/shared.module';
import { SimpleMappingListComponent } from './simple-mapping-list/simple-mapping-list.component';
import { TariffConfigEditorComponent } from './tariff-config-editor/tariff-config-editor.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faPlus,
  faPlusSquare,
  faEdit,
  faDownload,
  faUpload,
  faInfoCircle,
  faCheckCircle,
  faCloudDownloadAlt,
  faShareSquare,
  faExclamationTriangle,
  faArrowAltCircleLeft,
  faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {TariffVersionComponent} from "./tariff-version/tariff-version.component";
import {TariffVersionUpdateChecker} from "./tariff-version-update-checker/tariff-version-update-checker.component";

library.add(faTrash, faPlus, faPlusSquare, faEdit, faDownload, faUpload, faInfoCircle, faArrowAltCircleLeft,
  faCheckCircle, faCloudDownloadAlt, faShareSquare, faExclamationTriangle, faExclamationCircle);

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    SharedModule
  ],
  declarations: [
    TariffConfigsListComponent,
    TariffConfigsViewComponent,
    TariffConfigComponent,
    RateMappingListComponent,
    TariffVersionListComponent,
    TariffVersionComponent,
    TariffVersionUpdateChecker,
    SimpleMappingListComponent,
    TariffConfigEditorComponent,
    MasterTariffIdInputComponent,
    TariffConfigFileImportComponent
  ],
  exports: [
    TariffConfigsListComponent,
    TariffConfigsViewComponent
  ]
})
export class ConfigurationsModule { }

