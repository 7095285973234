import { Component, Input, OnInit } from '@angular/core';
import { LoadServingEntity } from '../../../domain/load-serving-entity';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'app-lse-view',
  templateUrl: './lse-view.component.html',
  styleUrls: ['./lse-view.component.css']
})
export class LseViewComponent implements OnInit {

  @Input() lse: LoadServingEntity;

  constructor(public admin: AdminService) {}

  ngOnInit() {
  }
}
