import {SourceControlStatus, TariffStatus} from "../../../domain/tariff-status";
import {TariffService} from "../../../services/tariff.service";
import {AdminService} from "../../../services/admin.service";
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SidenavService} from "../../../services/sidenav-service";
import {TariffBase} from "../../../domain/tariff-base";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tariff-source-control',
  templateUrl: './tariff-source-control.component.html',
  styleUrls: ['./tariff-source-control.component.css']
})
export class TariffSourceControlComponent implements OnChanges, OnDestroy {
  @Input() tariff: TariffBase;
  @Input() tariffType: string;
  sourceControlStatus: TariffStatus;
  commitMessage: string = 'Promoted from tariff-console';
  commitUser: string = '';

  constructor(private service: TariffService,
              private sidenavService: SidenavService,
              private modalService: NgbModal,
              private router: Router,
              public admin: AdminService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadSourceControlStatus();
  }

  ngOnDestroy(): void {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

  onPromoteTariff(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title'
      }).result.then(() => {
        const fullCommitMessage = `${this.commitUser}:  ${this.commitMessage}`;
        this.service.promoteTariff(this.tariff.code, fullCommitMessage).subscribe( () => {
          this.loadSourceControlStatus();
          this.sidenavService.refreshTariffs(this.tariffType);
        });
    }, () => {});
  }

  onRevertTariff() {
    const code = this.tariff.code;
    if (confirm(`Are you sure you want to revert ${code}?`)) {
      this.service.revertTariff(code).subscribe( () => {
        this.loadSourceControlStatus();
        this.sidenavService.refreshTariffs(this.tariffType);
      });
    }
  }

  onDeleteTariff() {
    const code = this.tariff.code;
    if (confirm(`Are you sure you want to delete ${code}?`)) {
      this.service.deleteTariffXml(code).subscribe( () => {
        this.sidenavService.refreshTariffs(this.tariffType);
        this.router.navigate(['delivery/']);
      });
    }
  }

  isModified(tariff: TariffStatus): boolean {
    return tariff ? tariff.gitStatus !== SourceControlStatus.UNMODIFIED : false;
  }

  isUntracked(tariff: TariffStatus): boolean {
    return tariff ? tariff.gitStatus === SourceControlStatus.UNTRACKED : false;
  }

  private loadSourceControlStatus() {
    if (this.tariff) {
      this.service.getTariffSourceControlStatus(this.tariff.code).subscribe(status => {
        this.sourceControlStatus = status;
      });
    }
  }
}
