import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {NgbModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { Ng5SliderModule } from 'ng5-slider';

import { IntervalChartComponent } from './interval-chart/interval-chart.component';
import { TimeOfUseRateListComponent } from './time-of-use-rate-list/time-of-use-rate-list.component';
import { TariffEntityComponent } from './tariff-entity/tariff-entity.component';
import { ContentLoadingComponent } from './content-loading/content-loading.component';
import { SimpleTextPrompt } from './simple-text-prompt/simple-text-prompt.component';
import { AdminControlComponent } from './admin-control/admin-control.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ConsumptionGraphComponent } from './consumption-graph/consumption-graph.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faCheck, faTimes, faLock, faLockOpen, faFile, faChevronRight, faChevronDown, faEdit, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { DateRangeStaticComponent } from './date-range-static/date-range-static.component';
import { DemandGraphComponent } from './demand-graph/demand-graph.component';
import { AboutBoxComponent} from "./about-box/about-box.component";
import { TouListComponent } from "./tou-list/tou-list.component";
import { TariffSidenavComponent } from "./tariff-sidenav/tariff-sidenav.component";
import { TariffSidenavUtilityGroupComponent } from './tariff-sidenav-utility-group/tariff-sidenav-utility-group.component';
import { TariffSourceControlComponent } from "./tariff-source-control/tariff-source-control.component";
import { TariffSitesComponent } from "./tariff-sites/tariff-sites.component";
import {RateSeriesIdListComponent} from "./rate-series-id-list/rate-series-id-list.component";
import {VtlFileUpdateComponent} from "./vtl-file-update/vtl-file-update.component";
import {VtlFileImportComponent} from "./vtl-file-import/vtl-file-import.component";

library.add(faSpinner, faCheck, faTimes, faLock, faLockOpen, faFile, faChevronRight, faChevronDown, faEdit, faFileImport);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
    FontAwesomeModule,
    Ng5SliderModule,
    NgbTooltipModule,
  ],
  declarations: [
    ContentLoadingComponent,
    SimpleTextPrompt,
    TariffEntityComponent,
    TariffSidenavComponent,
    TariffSourceControlComponent,
    TariffSitesComponent,
    DateRangeStaticComponent,
    TimeOfUseRateListComponent,
    TariffSidenavUtilityGroupComponent,
    TouListComponent,
    IntervalChartComponent,
    AdminControlComponent,
    DatepickerComponent,
    ConsumptionGraphComponent,
    DemandGraphComponent,
    AboutBoxComponent,
    RateSeriesIdListComponent,
    VtlFileImportComponent,
    VtlFileUpdateComponent
  ],
  exports: [
    ContentLoadingComponent,
    SimpleTextPrompt,
    TariffEntityComponent,
    TariffSidenavComponent,
    TariffSourceControlComponent,
    TariffSidenavUtilityGroupComponent,
    TimeOfUseRateListComponent,
    TouListComponent,
    IntervalChartComponent,
    AdminControlComponent,
    DatepickerComponent,
    DateRangeStaticComponent,
    ConsumptionGraphComponent,
    DemandGraphComponent,
    AboutBoxComponent,
    RateSeriesIdListComponent,
    VtlFileImportComponent,
    VtlFileUpdateComponent
  ]
})
export class SharedModule { }
