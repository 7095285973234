import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {SimpleCharges} from "../domain/simple-charges";
import {BillingPeriod} from "../domain/billing-period";

@Injectable({
  providedIn: 'root'
})
export class BillCalcService extends BaseService {
  private readonly rootPath = '/billing';

  constructor(private http: HttpClient) {
    super(http, "");
  }

  runBillCalc(startDate: string, stopDate: string, deliveryCode: string, supplyCode: string, file: any) : Observable<SimpleCharges> {
    let url = `${this.rootPath}/run?startDate=${startDate}&stopDate=${stopDate}&deliveryCode=${deliveryCode}&supplyCode=`;
    if (supplyCode) {
      url += supplyCode;
    }
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<SimpleCharges>( url, formData );
  }

  getBillingPeriods(file: any): Observable<Array<BillingPeriod>> {
    const url = `${this.rootPath}/periods`;
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<Array<BillingPeriod>>( url, formData );
  }
}
