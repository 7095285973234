import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TariffConfig} from '../../../domain/tariff-config';
import {TariffConfigService} from '../../../services/tariff-config.service';
import {AdminService} from '../../../services/admin.service';
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import * as moment from 'moment';
import {MasterTariffSidenavService} from "../../../services/master-tariff-sidenav.service";

@Component({
  selector: 'app-tariff-configs-list',
  templateUrl: './tariff-configs-list.component.html',
  styleUrls: ['../../shared/tariff-sidenav/tariff-sidenav.component.css']
})
export class TariffConfigsListComponent implements OnInit {
  @Input() configs: Array<SidenavTariff>;
  @Input() selectedTariff: SidenavTariff;

  constructor(private tariffConfigService: TariffConfigService,
              private masterTariffSidenavService: MasterTariffSidenavService,
              public admin: AdminService,
              private router: Router) {
  }

  ngOnInit() {}

  onImportTariffConfig($event) {
    // Because the Genability import takes awhile, explicitly set the loading, which also will get set by .refresh()
    this.masterTariffSidenavService.setLoading(true);

    const tariffId: number = $event.valueOf();
    this.tariffConfigService.importNewMasterTariff(tariffId).subscribe( (cfg: TariffConfig) => {
      this.masterTariffSidenavService.refresh();
      this.router.navigate(['configurations/' + cfg.masterTariffId]);
    });
    // TODO:   Put up an alert if the load fails, and setLoading(false)
  }

  onDownloadTariffVtl($event) {
    const tariffId: number = $event.valueOf();
    const year = moment().year();    // Always using current year for now.
    this.tariffConfigService.getVtl(tariffId, year).subscribe((vtl) => {
      const filename = `${tariffId}.csv`;
      const data = 'data:text/csv;charset=utf-8,' + encodeURI(vtl);
      const download = document.createElement('a');
      download.setAttribute('href', data);
      download.setAttribute('download', filename);
      download.click();
    });
  }
}
