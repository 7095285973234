import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffConfig } from '../../../domain/tariff-config';
import { TariffConfigService } from '../../../services/tariff-config.service';
import { TariffConfigRates } from '../../../domain/tariff-config-rates';
import { DeterminationRate } from '../../../domain/determination-rate';
import {RateMapping, RateMappingState} from '../../../domain/rate-mapping';
import { FlavorMapping } from '../../../domain/flavor-mapping';
import { ApplicabilityPair } from '../../../domain/applicability-pair';

@Component({
  selector: 'app-tariff-config-editor',
  templateUrl: './tariff-config-editor.component.html',
  styleUrls: ['./tariff-config-editor.component.css']
})
export class TariffConfigEditorComponent implements OnInit {
  @Input() tariffConfig: TariffConfig;
  @Output() tariffConfigChange = new EventEmitter<TariffConfig>();

  rates: TariffConfigRates;
  rateMap: Map<string, Array<DeterminationRate>>;
  rateGroupIncluded: { [key: string]: boolean };
  rateNameMap: Array<RateMapping>;
  editTariffConfig: TariffConfig;

  loading: boolean;
  dirty = false;

  constructor(private modalService: NgbModal,
              private tariffConfigService: TariffConfigService) { }

  ngOnInit() {}

  onOpen(content) {
    this.loadAllTariffInfo();

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result
      .then(() => {
        this.updateTariffConfigFromMaps();

        this.tariffConfigService.updateTariffConfig(this.editTariffConfig).subscribe( (newCfg) => {
          this.tariffConfigChange.emit(newCfg)
        });
      }, () => {});
  }

  private loadAllTariffInfo() {
    this.loading = true;

    // Get a fresh copy of the tariff config so we don't pollute the original while editing.
    this.tariffConfigService.getTariffConfig(this.tariffConfig.masterTariffId).subscribe( (newCfg) => {
      this.editTariffConfig = newCfg;

      this.initMapsFromTariffConfig();

      this.rateMap = new Map<string, Array<DeterminationRate>>();
      this.tariffConfigService.getTariffRates(this.editTariffConfig.masterTariffId).subscribe(data => {
        this.rates = data;

        for (const rate of this.rates.rates) {
          if (!this.rateMap.has(rate.rateGroupName)) {
            this.rateMap.set(rate.rateGroupName, []);
          }
          this.rateMap.get(rate.rateGroupName).push( rate );
        }
        // Sort the rates by name within each rate group.  (Alternatively we could sort descending by amount...)
        this.rateMap.forEach( (kv) => {
          kv.sort((a, b) => {
            return a.rateName.localeCompare(b.rateName);
          });
        });
        this.loading = false;
      });
    });
  }

  rateCharge(rate: DeterminationRate): string {
    if (rate.applicabilityKey) {
      const tariffVersion = this.findTariffVersion('default');
      const pair = this.findApplicabilityPair(tariffVersion, rate.applicabilityKey);
      return TariffConfigEditorComponent.getRateBandValue(rate.latestRateBands, pair.applicabilityValue);
    }
    return rate.latestRateBands[0][''];   // weird, but gets the single numeric value
  }

  toggleRateGroup(rateGroup: string) {
    this.rateGroupIncluded[rateGroup] = !this.rateGroupIncluded[rateGroup];
    this.dirty = true;
  }

  toggleRate(rate:  DeterminationRate) {
    if (this.rateToggleOk(rate)) {
      if (this.rateExcluded(rate)) {
        console.log(`Removing exclusion for ${rate.rateName}`);
        this.rateNameMap.forEach( (item, index) => {
          if (item.name === rate.rateName) {
            this.rateNameMap.splice(index, 1);
          }
        });
      } else {
        console.log(`Adding exclusion for ${rate.rateName}`);
        this.rateNameMap.push( { name: rate.rateName, type: RateMappingState.UNDETERMINED });
      }
      this.dirty = true;
    }
  }

  rateToggleOk(rate: DeterminationRate): boolean {
    const mapping: RateMapping = this.rateNameMap.find( r => r.name === rate.rateName );
    return !mapping || (mapping.type === RateMappingState.UNDETERMINED);
  }

  rateExcluded(rate: DeterminationRate): boolean {
    const mapping: RateMapping = this.rateNameMap.find( r => r.name === rate.rateName );
    return mapping && (mapping.type === RateMappingState.UNDETERMINED);
  }

  getApplicabilityInfo(applicabilityKey: string) {
    if (this.editTariffConfig.versions.length === 1) {
      const tariffVersion = this.findTariffVersion('default');
      const val = this.findApplicabilityPair(tariffVersion, applicabilityKey).applicabilityValue;
      return `${applicabilityKey}:  ${val}`;
    } else if (this.editTariffConfig.versions.length === 0) {
      return 'Tariff version applicability value (a tariff version is not yet defined).';
    } else {
      return 'Tariff version applicability value (there are multiple tariff versions).';
    }
  }

  newRateGroup(rateGroupName: string) {
    return !this.tariffConfig.autoSortMap.find(mp => mp.rateGroup === rateGroupName);
  }

  private findTariffVersion(name: string): FlavorMapping {
    return this.editTariffConfig.versions.find(v => v.name === name);
  }

  private findApplicabilityPair(tariffVersion: FlavorMapping, applicabilityKey: string): ApplicabilityPair {
    return tariffVersion.applicabilityPairs.find( ap => ap.applicabilityKey === applicabilityKey);
  }

  private static getRateBandValue(rateBands: Array<any>, key: string) {
    for (const rb of rateBands) {
      if (rb[key] !== undefined) {
        return rb[key];
      }
    }
    return '??';
  }

  private initMapsFromTariffConfig() {
    this.rateGroupIncluded = {};
    for (const entry of this.editTariffConfig.autoSortMap) {    // Use the new tariffConfig's autoSortMap
      // Look for each rate group in the OLD tariffConfig's rateGroupMap.   (This handles any changes from Genability
      // between the old and new)
      const rateMapping = this.tariffConfig.rateGroupMap.find(rm => rm.name === entry.rateGroup);
      this.rateGroupIncluded[entry.rateGroup] = !rateMapping ||
        (rateMapping.type !== RateMappingState.UNDETERMINED);
    }

    this.rateNameMap = [];
    for (const rate of this.tariffConfig.rateNameMap) {
      this.rateNameMap.push( { name: rate.name, type: rate.type} );
    }
  }


  private updateTariffConfigFromMaps() {
    // The rateGroupMap is wiped clean and refreshed entirely.
    this.editTariffConfig.rateGroupMap = [];
    for (const rg of Object.keys(this.rateGroupIncluded)) {
      if (!this.rateGroupIncluded[rg]) {
        this.editTariffConfig.rateGroupMap.push({ name : rg, type: RateMappingState.UNDETERMINED });
      }
    }

    this.editTariffConfig.rateNameMap = this.rateNameMap;
  }
}

