import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-interval-chart',
  templateUrl: './interval-chart.component.html',
  styleUrls: ['./interval-chart.component.css']
})
export class IntervalChartComponent implements OnInit, OnChanges {
  @Input() intervals: number;
  @Input() label: string;
  @Input() editable: boolean;
  @Output() change = new EventEmitter<number>();

  dashArrayStr: string;
  mins: number;
  availableIntervals = [5, 15, 30, 60];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.mins = 60 / this.intervals;
    const stroke = this.mins * 100 / 60;
    this.dashArrayStr = `${stroke} ${stroke}`;
  }

  select(minutes: number) {
    const prompt = `Are you sure you want to change the ${this.label} interval from ${this.mins} to ${minutes} minutes?`;
    // Only update if different from original selection
    if ((minutes !== this.mins) && confirm(prompt)) {
      this.intervals = 60 / minutes;    // Forces ngOnChanges
      this.change.emit(this.intervals);
    }
  }

}
