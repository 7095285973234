import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AdminService } from '../../../services/admin.service';
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {TariffBase} from "../../../domain/tariff-base";

@Component({
  selector: 'app-delivery-tariff-list',
  templateUrl: './delivery-tariff-list.component.html'
})
export class DeliveryTariffListComponent implements OnInit {
  @Input() tariffs: Array<SidenavTariff>;
  @Input() selectedTariff: TariffBase;
  @Output() selectedTariffChange: EventEmitter<TariffBase> = new EventEmitter<TariffBase>();

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

}
