import {BehaviorSubject, Observable} from "rxjs";
import {SidenavTariff} from "../domain/sidenav-tariff";
import {Injectable} from "@angular/core";
import {TariffConfigService} from "./tariff-config.service";
import {UtilityService} from "./utility.service";
import {SourceControlStatus} from "../domain/tariff-status";

@Injectable({
  providedIn: 'root'
})
export class MasterTariffSidenavService {

  private masterTariffs$: BehaviorSubject<Array<SidenavTariff>> = new BehaviorSubject<Array<SidenavTariff>>([]);
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private masterTariffService: TariffConfigService, private utilityService: UtilityService) {}

  public refresh() {
    this.loading$.next(true);

    this.masterTariffService.getAllTariffConfigs().subscribe( masterTariffs => {
      this.utilityService.getAllLSEs().subscribe( utilities => {
        const sidenavTariffs: Array<SidenavTariff> = [];
        for (let mt of masterTariffs) {
          const utility = utilities.find(ut => ut.lseId === mt.lseId);
          sidenavTariffs.push({
            code: mt.masterTariffId.toString(),
            name: `${mt.masterTariffId} / ${mt.code}`,
            utilityId: utility ? utility.id : `${mt.lseId}`,
            utilityName: utility ? utility.name : mt.utility,
            filename: undefined,
            // Minor HACK:   Even though master-tariffs aren't yet under source control, we use the gitStatus
            // as a means of highlighting a master tariff that has an unknown utility.
            gitStatus: utility ? undefined : SourceControlStatus.UNKNOWN
          });
        }

        this.masterTariffs$.next( MasterTariffSidenavService.sort(sidenavTariffs) );
        this.loading$.next(false);
      });
    });
  }

  public getMasterTariffs(): Observable<Array<SidenavTariff>> {
    return this.masterTariffs$.asObservable();
  }

  public isLoading(): Observable<boolean> {
    return this.loading$.asObservable();
  }

  public setLoading(b: boolean) {
    this.loading$.next(b);
  }

  private static sort(arr: Array<SidenavTariff>): Array<SidenavTariff> {
    arr.sort( (a, b) => {
      if (a.utilityName && b.utilityName && a.utilityName !== b.utilityName) {
        return a.utilityName.localeCompare(b.utilityName);
      } else {
        return a.code.localeCompare(b.code);
      }
    } );
    return arr;
  }

}
