import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';

import {DatepickerComponent} from '../datepicker/datepicker.component';
import {TariffEntity, DeliveryType, SupplyType} from '../../../domain/tariff-entity';
import {TariffService} from '../../../services/tariff.service';
import {RateSeries} from '../../../domain/rate-series';
import {RateSeriesGraphBaseComponent} from '../rate-series-graph-base/rate-series-graph.base.component';
import {Tou} from "../../../domain/tou";
import {TariffBase} from "../../../domain/tariff-base";

@Component({
  selector: 'app-consumption-graph',
  templateUrl: './consumption-graph.component.html',
  styleUrls: ['./consumption-graph.component.css']
})
export class ConsumptionGraphComponent extends RateSeriesGraphBaseComponent implements OnInit, OnChanges {
  @Input() tariff: TariffBase;
  @Input() currentTariffType: string;

  @ViewChild(DatepickerComponent, { static: true }) datePicker;
  @ViewChild('pointStrategy', { static: true }) pointStrategy;

  initializationComplete: boolean;
  tariffDropdownListings: TariffEntity[];
  private secondTariff: string;
  private rateSeries: Array<RateSeries>;

  constructor(private tariffService: TariffService, modalService: NgbModal) {
    super(modalService);
  }

  ngOnInit() {
    this.initTimeWindow();
    this.secondTariff = '';

    this.getOtherTariffs().subscribe(data => {
      this.tariffDropdownListings = data;
      this.tariffDropdownListings.sort((a, b)  =>
        (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0)
      );
    });

    this.initializationComplete = true;
    this.repaintGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initializationComplete) {
      this.repaintGraph();
    }
  }

  private getOtherTariffs(): Observable<Array<TariffEntity>> {
    return this.tariffService.getTariffs(this.getInverseRateType());
  }

  setTariffSelection(code) {
    this.secondTariff = code;
    this.repaintGraph();
  }

  getInverseRateType(): string {
    return (this.currentTariffType === DeliveryType) ? SupplyType : DeliveryType;
  }

  repaintGraph(): void {
    const deliveryTariff = (this.currentTariffType === DeliveryType ? this.tariff.code : this.secondTariff);
    const supplyTariff = (this.currentTariffType === DeliveryType ? this.secondTariff : this.tariff.code);
    const endStamp = this.pointStrategy.nativeElement.checked;
    this.selectedPointRates = [];
    this.tariffService.getConsumption(
      deliveryTariff,
      supplyTariff,
      this.startDate.toISOString(),
      this.endDate.toISOString(),
      endStamp).subscribe(data => {
        this.rateSeries = data;
        this.buildSeries(this.rateSeries, '$/kWh', 'chartContainer', endStamp);
      }
    );
  }

  getDatePicker(): DatepickerComponent {
    return this.datePicker;
  }

  getTous(pointDateTime: string): Observable<Array<Tou>> {
    const deliveryTariff = (this.currentTariffType === DeliveryType ? this.tariff.code : this.secondTariff);
    const supplyTariff = (this.currentTariffType === DeliveryType ? this.secondTariff : this.tariff.code);
    return this.tariffService.getConsumptionTous(deliveryTariff, supplyTariff, pointDateTime);
  }

}
