import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-master-tariff-id-input',
  templateUrl: './master-tariff-id-input.component.html',
  styleUrls: ['./master-tariff-id-input.component.css']
})
export class MasterTariffIdInputComponent implements OnInit {
  @Output() masterTariffIdImport: EventEmitter<number> = new EventEmitter<number>();
  @Output() masterTariffIdDownload: EventEmitter<number> = new EventEmitter<number>();
  masterTariffId: number;
  importTariff: boolean;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {
    this.importTariff = true;
  }

  open(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'sm'
      }).result.then(() => {
        if (this.importTariff) {
          this.masterTariffIdImport.emit(this.masterTariffId);
        } else {
          this.masterTariffIdDownload.emit(this.masterTariffId);
        }
    }, () => {});
  }
}
