import {Component, Input, OnInit} from '@angular/core';
import {TariffService} from '../../../services/tariff.service';
import {AdminService} from '../../../services/admin.service';
import {TariffBase} from "../../../domain/tariff-base";

@Component({
  selector: 'app-tariff-entity',
  templateUrl: './tariff-entity.component.html',
  styleUrls: ['./tariff-entity.component.css']
})
export class TariffEntityComponent implements OnInit {
  @Input() tariff: TariffBase;
  @Input() masterTariffId: string;
  @Input() tariffType: string;

  staticTariffs = false;
  loading = false;

  constructor(public admin: AdminService,
              private service: TariffService) { }

  ngOnInit() {
    this.staticTariffs = TariffService.isFileBased;
  }

  onExportTariffXml() {
    this.loading = true;
    this.service.getTariffXml(this.tariff.code).subscribe( (xmlData) => {
      const filename = this.tariff.code + '.xml';
      const data = 'data:application/xml;charset=utf-8,' + encodeURI(xmlData);
      const download = document.createElement('a');
      download.setAttribute('href', data);
      download.setAttribute('download', filename);
      download.click();

      this.loading = false;
    });
  }

}
