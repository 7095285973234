
export interface TariffStatus {
  tariffCode: string;
  filename: string;
  gitStatus: SourceControlStatus;
  tariffType: string;
}

export enum SourceControlStatus {
  MODIFIED = "MODIFIED",
  UNMODIFIED = "UNMODIFIED",
  UNTRACKED = "UNTRACKED",
  UNKNOWN = "UNKNOWN"  // Only used by the UI
}
