import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupplyTariffsViewComponent } from './components/supply/supply-tariffs-view/supply-tariffs-view.component';
import { DeliveryTariffsViewComponent } from './components/delivery/delivery-tariffs-view/delivery-tariffs-view.component';
import { TariffConfigsViewComponent } from './components/configurations/tariff-configs-view/tariff-configs-view.component';
import { LsesViewComponent } from './components/lses/lses-view/lses-view.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'supply',
    pathMatch: 'full'
  },

  {
    path: 'supply',
    children: [
      {
        path: '',
        component: SupplyTariffsViewComponent
      },
      {
        path: ':code',
        component: SupplyTariffsViewComponent
      }
    ]
  },

  {
    path: 'delivery',
    children: [
      {
        path: '',
        component: DeliveryTariffsViewComponent
      },
      {
        path: ':code',
        component: DeliveryTariffsViewComponent
      }
    ]
  },

  // TODO:  Add canActivate: [TariffConfigGuardService] to disable route for file-based rate-service.
  {
    path: 'configurations',
    children: [
      {
        path: '',
        component: TariffConfigsViewComponent
      },
      {
        path: ':id',
        component: TariffConfigsViewComponent,
        runGuardsAndResolvers: 'always'
      }
    ]
  },

  {
    path: 'lses',
    children: [
      {
        path: '',
        component: LsesViewComponent
      },
      {
        path: ':id',
        component: LsesViewComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
        onSameUrlNavigation: 'reload'
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
