import {Component, OnDestroy, OnInit} from '@angular/core';
import {SidenavService} from "../../../services/sidenav-service";
import {Observable, Subscription} from "rxjs";
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {DeliveryType} from "../../../domain/tariff-entity";

@Component({
  selector: 'app-delivery-tariffs-view',
  templateUrl: './delivery-tariffs-view.component.html',
  styleUrls: ['./delivery-tariffs-view.component.css']
})
export class DeliveryTariffsViewComponent implements OnInit, OnDestroy {
  loading: Observable<boolean>;
  sidenavSubscription: Subscription;
  tariffs: Array<SidenavTariff>;
  selectedTariff: SidenavTariff;

  constructor(
    private sidenavService: SidenavService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.loading = this.sidenavService.isLoading();

    this.sidenavSubscription = this.sidenavService.getTariffs(DeliveryType).subscribe( data => {
      this.tariffs = data;
      this.findSelectedTariff();
    } );
  }

  ngOnDestroy() {
    this.sidenavSubscription.unsubscribe();
  }

  private findSelectedTariff() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const code = params.get('code');
      for (const tariff of this.tariffs) {
        if (tariff.code === code) {
          this.selectedTariff = tariff;
        }
      }
    });
  }

}
