import {Component, Input, OnInit} from '@angular/core';
import {RateMapping} from '../../../domain/rate-mapping';

@Component({
  selector: 'app-rate-mapping-list',
  templateUrl: './rate-mapping-list.component.html',
  styleUrls: ['./rate-mapping-list.component.css']
})
export class RateMappingListComponent implements OnInit {

  @Input() mappings: Array<RateMapping>;
  @Input() title: string;
  navbarCollapsed = true;

  constructor() { }

  ngOnInit() {
    this.navbarCollapsed = true;
  }

  toggleCollapsed() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

}
