import { Component, Input, OnInit } from '@angular/core';
import { LoadServingEntity } from '../../../domain/load-serving-entity';

@Component({
  selector: 'app-lse-list',
  templateUrl: './lse-list.component.html',
  styleUrls: ['./lse-list.component.css']
})
export class LseListComponent implements OnInit {

  @Input() lses: Array<LoadServingEntity>;

  constructor() { }

  ngOnInit() {
  }

}
