import {TimeOfUseRate} from '../domain/time-of-use-rate';

export class TimeOfUseRateService {

  static getMinCharge(arr: TimeOfUseRate[]) {
    let minCharge = 9999.99;
    for (const rate of arr) {
      minCharge = Math.min( minCharge, ...rate.ratePeriods.map(r => r.rate) );
    }
    return minCharge;
  }


  static getMaxCharge(arr: TimeOfUseRate[]) {
    let maxCharge = -9999.99;
    for (const rate of arr) {
      maxCharge = Math.max( maxCharge, ...rate.ratePeriods.map(r => r.rate) );
    }
    return maxCharge;
  }

}
