import {Component, Input, OnChanges} from "@angular/core";
import {TariffBase} from "../../../domain/tariff-base";
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {SourceControlStatus} from "src/app/domain/tariff-status";

@Component({
  selector: 'app-tariff-sidenav-utility-group',
  templateUrl: './tariff-sidenav-utility-group.component.html',
  styleUrls: ['./tariff-sidenav-utility-group.component.css']
})
export class TariffSidenavUtilityGroupComponent implements OnChanges {
  @Input() utilityId;
  @Input() utilityName;
  @Input() tariffType: string;
  @Input() selectedTariff: TariffBase;
  @Input() tariffs: Array<SidenavTariff>;
  @Input() expand: boolean;

  isCurrentUtility: boolean;
  isCollapsed: boolean;

  ngOnChanges(): void {
    this.isCurrentUtility = this.selectedTariff && (this.selectedTariff.utilityId === this.utilityId);
    this.isCollapsed = this.expand ? false : !this.isCurrentUtility;
  }

  sourceControlStatus(tariff: SidenavTariff): string {
    // TODO:  i18n solution
    switch (tariff.gitStatus) {
      case SourceControlStatus.MODIFIED: return "modified";
      case SourceControlStatus.UNMODIFIED: return "promoted";
      case SourceControlStatus.UNTRACKED: return "new";
    }
    return "";
  }

  isModified(tariff: SidenavTariff): boolean {
    return tariff.gitStatus && (tariff.gitStatus !== SourceControlStatus.UNMODIFIED);
  }

  anyModified(tariffs: Array<SidenavTariff>): boolean {
    return tariffs.filter(t => this.isModified(t) ).length > 0;
  }

  unknownUtility(tariffs: Array<SidenavTariff>): boolean {
    // DEPENDENCY:  MasterTariffSidenavService flags unknown utilities with gitStatus = UNKNOWN
    return tariffs.filter(t => t.gitStatus === SourceControlStatus.UNKNOWN ).length > 0;
  }

}
