import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ServiceInfo} from "../domain/service-info";

export class BaseService {
  private readonly baseUrl: string;
  private httpClient: HttpClient;

  constructor(http: HttpClient, baseUrl: string) {
    this.httpClient = http;
    this.baseUrl = baseUrl;
  }

  getServiceInfo(): Observable<ServiceInfo> {
    const options = {};
    return this.httpClient.get<ServiceInfo>(this.baseUrl + '/info/', options);
  }
}
