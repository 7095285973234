import {Component, EventEmitter, Input, OnChanges, OnInit} from '@angular/core';
import { Options } from 'ng5-slider';

import {TimeOfUseRate} from '../../../domain/time-of-use-rate';
import {TimeOfUseRateService} from '../../../services/time-of-use-rate-service';
import * as moment from 'moment';
import {RateTimePeriod} from "../../../domain/rate-time-period";

const DOWMAP = new Map([
  ["SUNDAY", "S"],
  ["MONDAY", "M"],
  ["TUESDAY", "T"],
  ["WEDNESDAY", "W"],
  ["THURSDAY", "Th"],
  ["FRIDAY", "F"],
  ["SATURDAY", "Sa"],
]);

@Component({
  selector: 'app-time-of-use-rate-list',
  templateUrl: './time-of-use-rate-list.component.html',
  styleUrls: ['./time-of-use-rate-list.component.css']
})
export class TimeOfUseRateListComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() units: string;
  @Input() touRates: TimeOfUseRate[];

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  filteredRates: TimeOfUseRate[];
  navbarCollapsed = true;
  showExpired = true;
  countExpired: number;
  minCharge: number;
  maxCharge: number;
  filterVal: number;
  options: Options = {
    showTicks: true,
    translate: (value: number): string => {
      return '$' + value.toFixed(2);
    }
  };

  constructor() { }

  ngOnInit() {}

  ngOnChanges(): void {
    this.navbarCollapsed = true;
    this.showExpired = true;
    this.minCharge = TimeOfUseRateService.getMinCharge(this.touRates);
    this.maxCharge = TimeOfUseRateService.getMaxCharge(this.touRates);

    this.updateSlider( Math.floor(this.minCharge), Math.round(this.maxCharge * 100) / 100);
    this.onFilter();
  }

  updateSlider(min: number, max: number) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = min;
    newOptions.ceil = max;
    newOptions.step = (max - min < 0.5) ? 0.1 : 0.5;
    this.options = newOptions;
    this.filterVal = min;
    this.manualRefresh.emit();
  }

  toggleCollapsed() {
    this.navbarCollapsed = !this.navbarCollapsed;
    this.manualRefresh.emit();
  }

  onFilter() {
    this.countExpired = 0;
    this.filteredRates = [];
    for (const rate of this.touRates) {
      if (this.isExpired(rate)) {
        ++this.countExpired;
        if (!this.showExpired) {
          continue;
        }
      }

      const filteredRate: TimeOfUseRate = {
        name: rate.name,
        daysOfWeek: rate.daysOfWeek,
        timeSpans: rate.timeSpans,
        ratePeriods: [],
        seasonFrom: rate.seasonFrom,
        seasonTo: rate.seasonTo
      };

      // Only copy date ranges that meet filter
      for (const range of rate.ratePeriods) {
        if (range.rate >= this.filterVal && !this.filteredRates.includes(rate)) {
          filteredRate.ratePeriods.push(range);
        }
      }
      if (filteredRate.ratePeriods.length > 0) {
        this.filteredRates.push(filteredRate);
      }
    }
  }

  isExpired(rate: TimeOfUseRate): boolean {
    for (const range of rate.ratePeriods) {
    if (moment(range.stopDate).isAfter(moment())) {
        return false;
      }
    }
    return true;
  }

  is24x7(period: RateTimePeriod) {
    return period.allDay;
  }

  startTime(period: RateTimePeriod) {
    return moment(period.startTime, 'HH:mm:ss').format("hh:mm A");
  }


  stopTime(period: RateTimePeriod) {
    return moment(period.stopTime, 'HH:mm:ss').format("hh:mm A");
  }

  daysOfWeekStr(tou: TimeOfUseRate): string {
    let daysOfWeekStr = "";
    DOWMAP.forEach((val: string, key: string) => {
      if (tou.daysOfWeek.includes(key)) {
        daysOfWeekStr += val;
      }
    });
    return daysOfWeekStr;
  }

  seasonalRange(tou: TimeOfUseRate): string {
    if (tou.seasonFrom || tou.seasonTo) {
      return moment(tou.seasonFrom).format('M/D') + ' - ' + moment(tou.seasonTo).format('M/D');
    }
    return "";
  }
}
