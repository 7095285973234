import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { SupplyTariff } from '../../../domain/supply-tariff';
import { TariffService } from '../../../services/tariff.service';
import {TariffBase} from "../../../domain/tariff-base";

@Component({
  selector: 'app-supply-tariff',
  templateUrl: './supply-tariff.component.html',
  styleUrls: ['./supply-tariff.component.css']
})
export class SupplyTariffComponent implements OnInit, OnChanges {
  @Input() tariff: TariffBase;
  supplyTariff: SupplyTariff;

  constructor(private tariffService: TariffService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tariff) {
      this.tariffService.getSupplyTariff(this.tariff.code).subscribe( data => {
        this.supplyTariff = data;
      });
    }
  }

  cronReadable(cadence: string) {
    return TariffService.cronReadable(cadence);
  }
}
