import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TariffConsoleServiceInfo} from "../domain/tariff-console-service-info";
import {HttpClient} from "@angular/common/http";
import {TariffConsoleConfigProperties} from "../domain/tariff-console-config-properties";

@Injectable({
  providedIn: 'root'
})
export class TariffConsoleService {
  constructor(private http: HttpClient) { }

  // TODO:  Can payload be modified to match ServiceInfo so we can extend BaseService?
  getInfo(): Observable<TariffConsoleServiceInfo> {
    const options = {};
    return this.http.get<TariffConsoleServiceInfo>('/info/', options);
  }

  getConfigProperties(): Observable<TariffConsoleConfigProperties> {
    const options = {};
    return this.http.get<TariffConsoleConfigProperties>('/config', options);
  }
}
