import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffConfigService } from '../../../services/tariff-config.service';
import {MasterTariffSidenavService} from "../../../services/master-tariff-sidenav.service";

@Component({
  selector: 'app-tariff-config-file-import',
  templateUrl: './tariff-config-file-import.component.html',
  styleUrls: ['./tariff-config-file-import.component.css']
})
export class TariffConfigFileImportComponent implements OnInit {

  @Input() masterTariffId: number;
  fileData: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private tariffConfigService: TariffConfigService,
    private masterTariffSidenavService: MasterTariffSidenavService
  ) { }

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title'
      }).result.then(() => {
        this.tariffConfigService.updateTariffConfigRaw(this.masterTariffId, this.fileData).subscribe( () => {
          this.masterTariffSidenavService.refresh();
          this.router.navigate(['configurations/' + this.masterTariffId]);
        });
    }, () => {});
  }

  onChooseFile(event: any) {
    const reader = new FileReader();
    reader.readAsText(event.target.files[0]);       // UTF-8 is assumed
    reader.onload = () => {
      this.fileData = reader.result.toString();
    };
  }

}
