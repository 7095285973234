import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TariffEntity } from '../../../domain/tariff-entity';
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {TariffBase} from "../../../domain/tariff-base";
import {AdminService} from "../../../services/admin.service";

@Component({
  selector: 'app-supply-tariff-list',
  templateUrl: './supply-tariff-list.component.html'
})
export class SupplyTariffListComponent implements OnInit {
  @Input() tariffs: Array<SidenavTariff>;
  @Input() selectedTariff: TariffBase;
  @Output() selectedTariffChange: EventEmitter<TariffBase> = new EventEmitter<TariffEntity>();

  constructor(public admin: AdminService) { }

  ngOnInit() {
  }

}
