import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {RateSeries} from "../../../domain/rate-series";

@Component({
  selector: 'app-rate-series-id-list',
  templateUrl: './rate-series-id-list.component.html',
  styleUrls: ['./rate-series-id-list.component.css']
})
export class RateSeriesIdListComponent implements OnChanges {
  @Input() rateSeries: Array<RateSeries>
  mappedRateSeries: Map<string, Array<RateSeries>>;

  ngOnChanges(changes: SimpleChanges): void {
    this.mappedRateSeries = new Map<string, Array<RateSeries>>();
    if (this.rateSeries.length > 0) {
      this.rateSeries.forEach( rs => {
        if (!this.mappedRateSeries.has(rs.masterId)) {
          this.mappedRateSeries.set(rs.masterId, []);
        }
        this.mappedRateSeries.get(rs.masterId).push(rs);
      })
    }
  }

}
