import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TariffConfig} from '../../../domain/tariff-config';
import {DeliveryType, TariffEntity} from '../../../domain/tariff-entity';
import {TariffConfigService} from '../../../services/tariff-config.service';
import {LoadServingEntity} from '../../../domain/load-serving-entity';
import {TariffService} from '../../../services/tariff.service';
import {AdminService} from '../../../services/admin.service';
import {SidenavService} from "../../../services/sidenav-service";
import {MasterTariffSidenavService} from "../../../services/master-tariff-sidenav.service";

@Component({
  selector: 'app-tariff-config',
  templateUrl: './tariff-config.component.html',
  styleUrls: ['./tariff-config.component.css']
})
export class TariffConfigComponent implements OnInit, OnChanges {

  @Input() masterTariffId: number;
  @Input() lses: Array<LoadServingEntity>;
  loading: boolean;
  config: TariffConfig;

  tariffs: Array<TariffEntity> = [];

  constructor(private router: Router,
              public admin: AdminService,
              private tariffConfigService: TariffConfigService,
              private tariffService: TariffService,
              private masterTariffSidenavService: MasterTariffSidenavService,
              private deliverySidenavService: SidenavService) {
  }

  ngOnChanges(): void {
    this.loading = true;
    this.tariffConfigService.getTariffConfig(this.masterTariffId).subscribe( (cfg) => {
      cfg.autoSortMap.sort( (a, b) => a.rateGroup.localeCompare(b.rateGroup) );
      cfg.rateNameMap.sort( (a, b) => a.name.localeCompare(b.name) );

      this.config = cfg;
      this.tariffConfigService.getTariffsForConfig(cfg.masterTariffId).subscribe( tariffs => {
        this.tariffs = tariffs;
      });

      this.loading = false;
    });
  }

  ngOnInit() {
  }

  onDeleteTariffConfig(cfg: TariffConfig) {
    if (this.tariffs.length > 0) {
      alert(`There are ${this.tariffs.length} tariffs configured for this master tariff.
      Deleting is not allowed until the tariffs are deleted.`);
      return;
    }

    if (confirm(`Are you sure you want to delete ${cfg.code}?`)) {
      this.tariffConfigService.deleteTariffConfig(cfg).subscribe( t => {
        console.log('Successfully deleted ', t);
        this.masterTariffSidenavService.refresh();
        this.router.navigate(['configurations']);
      });
    }
  }

  onRenameTariffConfig(newCode: string) {
    this.config.code = newCode;
    this.updateTariffConfig(false);
  }

  onUpdateSMI($event) {
    this.config['smart-meter-interval'] = $event.valueOf();
    this.updateTariffConfig(true);
  }

  onUpdateDCI($event) {
    this.config['demand-calc-interval'] = $event.valueOf();
    this.updateTariffConfig(true);
  }

  onExportTariffConfig() {
    // Get a clean version of the tariff config from the server rather than the one in-memory,
    // which may contain additional UI-only elements.
    this.tariffConfigService.getTariffConfig(this.config.masterTariffId).subscribe( (cfg) => {
      const data = 'data:application/json;charset=utf-8,' + encodeURI(
        JSON.stringify(cfg, null, 2)
      );
      const filename = `${cfg.masterTariffId} - ${cfg.code}.json`;
      const download = document.createElement('a');
      download.setAttribute('href', data);
      download.setAttribute('download', filename);
      download.click();
    });
  }

  lseExists(lseId: number): boolean {
    if (this.lses && lseId) {
      return !(this.lses.find(lse => lse.lseId === lseId) === undefined);
    }
    return false;
  }

  lseUUID(lseId: number): string {
    return this.lseExists(lseId) ? this.lses.find(lse => lse.lseId === lseId).id : "";
  }


  onTariffCreated(flavorName: string) {
    console.log(`created tariff for ${flavorName}`);
    this.loading = true;
    this.deliverySidenavService.refreshTariffs(DeliveryType);
    this.tariffConfigService.getTariffsForConfig(this.config.masterTariffId).subscribe( tariffs => {
      this.tariffs = tariffs;
      this.loading = false;
    });
  }

  onUpdateTariff(tariff: TariffEntity) {
    this.loading = true;
    this.tariffService.regenerateTariffVersion(tariff.code, tariff.name).subscribe( xml => {
      this.tariffService.putDeliveryTariff(tariff.code, xml).subscribe( () => {
        this.deliverySidenavService.refreshTariffs(DeliveryType);
        this.loading = false;
      })
    });
  }

  private updateTariffConfig(updateTariff: boolean) {
    this.tariffConfigService.updateTariffConfig(this.config).subscribe( () => {
      if (updateTariff) {
        const t: TariffEntity = this.findSingleAssociatedTariff();
        if (t !== null) {
          this.onUpdateTariff(t);
        }
      }
    });
  }

  private findSingleAssociatedTariff(): TariffEntity {
    const tariffs: Array<TariffEntity> = [];
    for (const flavor of this.config.versions ) {
      const tariff = this.tariffs.find(t => t.options === flavor.name);
      if (tariff) {
        tariffs.push(tariff);
      }
    }
    if (tariffs.length > 1) {
      alert(`There are ${tariffs.length} tariffs configured for this master tariff.  They must be manually updated with the change.`);
    }
    return tariffs.length == 1 ? tariffs[0] : null;
  }
}
