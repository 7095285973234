import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-content-loading',
  templateUrl: './content-loading.component.html',
  styleUrls: ['./content-loading.component.css']
})
export class ContentLoadingComponent implements OnInit {
  @Input() size = '1x';

  constructor() { }

  ngOnInit() {
  }

}
