
export interface RateMapping {
  name: string;
  type: string;
}

export enum RateMappingState {
  MonthlyContractDemandRates = 'MonthlyContractDemandRates',
  DeliveryRates = 'DeliveryRates',
  DailyDeliveryRates = 'DailyDeliveryRates',
  MonthlyDeliveryRates = 'MonthlyDeliveryRates',
  MonthlyEnergyRates = 'MonthlyEnergyRates',
  MonthlyServicesRates = 'MonthlyServicesRates',
  MacRate = 'MacRate',
  UNDETERMINED = 'UNDETERMINED'
}
