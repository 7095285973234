import {Component, OnInit} from "@angular/core";
import {TariffConsoleService} from "../../../services/tariff-console.service";

@Component({
  selector: 'app-about-box',
  templateUrl: './about-box.component.html',
  styleUrls: ['./about-box.component.css']
})
export class AboutBoxComponent implements OnInit {
  version: string = "(version)";
  feature: string = "";

  constructor(private tariffConsoleService: TariffConsoleService) {
  }

  ngOnInit() {
    this.tariffConsoleService.getInfo().subscribe( svcInfo => {
      this.version = `${svcInfo.build.version}.${svcInfo.build.jenkins['build-number']}`;
      this.feature = `${svcInfo.build['deros-feature']['version']}`;
    })
  }
}
