import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DeliveryTariff } from '../../../domain/delivery-tariff';
import { TariffService } from '../../../services/tariff.service';
import { AdminService } from "../../../services/admin.service";
import { TariffBase } from "../../../domain/tariff-base";
import {SidenavService} from "../../../services/sidenav-service";
import {DeliveryType} from "../../../domain/tariff-entity";

@Component({
  selector: 'app-delivery-tariff',
  templateUrl: './delivery-tariff.component.html',
  styleUrls: ['./delivery-tariff.component.css']
})
export class DeliveryTariffComponent implements OnInit, OnChanges {
  @Input() tariff: TariffBase;
  deliveryTariff: DeliveryTariff;

  constructor(private tariffService: TariffService,
              private sidenavService: SidenavService,
              public admin: AdminService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tariff) {
      this.tariffService.getDeliveryTariff(this.tariff.code).subscribe(data => {
        this.deliveryTariff = data;
      });
    }
  }

  onUpdateSMI($event) {
    const oldVal = this.deliveryTariff.smartMeterIntervals.toString();
    const newVal = $event.valueOf();
    this.deliveryTariff.smartMeterIntervals = newVal;
    this.updateTariffXml("smart-meter-intervals", oldVal, newVal);
  }

  onUpdateDCI($event) {
    const oldVal = this.deliveryTariff.demandCalcIntervals.toString();
    const newVal = $event.valueOf();
    this.deliveryTariff.demandCalcIntervals = newVal;
    this.updateTariffXml("demand-calc-intervals", oldVal, newVal);
  }


  private updateTariffXml(attribute: string, oldVal: string, newVal: Object) {
    if (this.deliveryTariff.masterTariffId !== null) {
      console.error('Attempt to update a non-Genability tariff.   This operation is not allowed!');
      return;
    }

    this.tariffService.getTariffXml(this.deliveryTariff.code).subscribe((oldXml) => {
      // Hackish:   Modify the xml with string replacements.
      const searchString = `${attribute}=\"${oldVal}\"`;
      const replaceString = `${attribute}=\"${newVal}\"`;
      const newXml = oldXml.includes(searchString) ? oldXml.replace(searchString, replaceString) : null;
      if (newXml === null) {
        console.error(`Could not update ${attribute} in delivery tariff!`);
      } else {
        this.tariffService.putDeliveryTariff(this.deliveryTariff.code, newXml).subscribe(data => {
          this.sidenavService.refreshTariffs(DeliveryType);
          this.tariff = data;
        });
      }
    });
  }

  cronReadable(cadence: string) {
    return TariffService.cronReadable(cadence);
  }
}
