import {Component, Input, OnInit} from "@angular/core";
import {FlavorMapping, UIFlavorMapping} from "../../../domain/flavor-mapping";
import {TariffEntity} from "../../../domain/tariff-entity";
import {TariffService} from "../../../services/tariff.service";

@Component({
  selector: 'app-tariff-version-update-checker',
  templateUrl: './tariff-version-update-checker.component.html',
  styleUrls: ['./tariff-version-update-checker.component.css']
})
export class TariffVersionUpdateChecker implements OnInit {
  @Input() masterTariffId: string;
  @Input() tariff: TariffEntity;
  @Input() mapping: UIFlavorMapping;

  public loading: boolean;
  tariffUpdateMap: Map<string, boolean>;

  constructor(private tariffService: TariffService) {}

  ngOnInit(): void {
    this.tariffUpdateMap = new Map<string, boolean>();
  }

  checkForTariffUpdates(mapping: FlavorMapping) {
    this.loading = true;
    // Generate the new tariff xml
    this.tariffService.regenerateTariffVersion(this.tariff.code, this.tariff.name).subscribe( (newXml) => {
      // Get the old tariff xml
      this.tariffService.getTariffXml(this.tariff.code).subscribe( (oldXml) => {
        if (oldXml === newXml) {
          console.log('Tariff xml has not changed');
          this.tariffUpdateMap.set(mapping.name, true);
        } else {
          this.tariffUpdateMap.set(mapping.name, false);

          console.log('Tariff has changed!   Downloading new tariff.');
          const filename = this.tariff.code + '.xml';
          const data = 'data:application/xml;charset=utf-8,' + encodeURI(newXml);
          const download = document.createElement('a');
          download.setAttribute('href', data);
          download.setAttribute('download', filename);
          download.click();
        }
        this.loading = false;
      });
    });
  }
}
