import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private readonly TOP_SECRET_PW = 'bruno';
  private adminMode;

  constructor() {
    this.adminMode = environment.adminMode;
  }

  isAdminMode(): boolean {
    return this.adminMode;
  }

  authenticate(passPhrase: string): boolean {
    // TODO:  Move this logic down to tariff-console-svc.
    this.adminMode = (passPhrase === this.TOP_SECRET_PW);
    return this.adminMode;
  }
}
