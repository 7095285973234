import {Component, Input, OnInit} from "@angular/core";
import {Tou} from "../../../domain/tou";
import {RatePeriod} from "../../../domain/rate-period";
import {TimeSpan} from "../../../domain/time-span";
import * as moment from 'moment';

// TODO:  DER-8160
// These UI class definitions are temporary until rate-service gets it's LocalDate and LocalTime serialization issues
// fixed.   They handle the parsing of LocalDate/Time for presentation.
class UIRatePeriod implements RatePeriod {
  rate: number;
  startDate: string;
  stopDate: string;

  constructor(rp: RatePeriod) {
    this.rate = rp.rate;
    this.startDate = UIRatePeriod.toIsoDate(rp.startDate);
    this.stopDate = UIRatePeriod.toIsoDate(rp.stopDate);
  }

  private static toIsoDate(orig: string): string {
    const els = orig.toString().split(',');
    const yr = els[0];
    const mo = els[1];
    const day = els[2];
    return moment(`${yr}-${mo}-${day}`, 'YYYY-M-D').format('M/D/YY');
  }
}

class UITimeSpan implements TimeSpan {
  acrossMidnight: boolean;
  allDay: boolean;
  startTime: string;
  stopTime: string;

  constructor(ts: TimeSpan) {
    this.acrossMidnight = ts.acrossMidnight;
    this.allDay = ts.allDay;
    this.startTime = UITimeSpan.toIsoTime(ts.startTime);
    this.stopTime = UITimeSpan.toIsoTime(ts.stopTime);
  }

  private static toIsoTime(orig: string): string {
    const els = orig.toString().split(',');
    const hr = els[0];
    const min = els[1];
    return moment(`${hr}:${min}`, 'H:m').format('hh:mm A');
  }
}

const DOWMAP = new Map([
  ["SUNDAY", "S"],
  ["MONDAY", "M"],
  ["TUESDAY", "T"],
  ["WEDNESDAY", "W"],
  ["THURSDAY", "Th"],
  ["FRIDAY", "F"],
  ["SATURDAY", "Sa"],
]);

class UITou implements Tou {
  name: string;
  ratePeriods: Array<RatePeriod> = new Array<RatePeriod>();
  timeSpans: Array<TimeSpan> = new Array<TimeSpan>();
  daysOfWeekStr: string;

  constructor(tou: Tou) {
    this.name = tou.name;
    for (let rp of tou.ratePeriods) {
      this.ratePeriods.push( new UIRatePeriod(rp) );
    }
    for (let ts of tou.timeSpans) {
      this.timeSpans.push( new UITimeSpan(ts) );
    }
    const tmpDOW: string[] = tou['daysOfWeek'];
    this.daysOfWeekStr = "";
    DOWMAP.forEach((val: string, key: string) => {
      if (tmpDOW.includes(key)) {
        this.daysOfWeekStr += val;
      }
    });
  }
}
// -------------- END TEMPORARY

@Component({
  selector: 'app-tou-list',
  templateUrl: './tou-list.component.html',
  styleUrls: ['./tou-list.component.css']
})
export class TouListComponent implements OnInit {
  @Input() tous: Array<Tou>;
  @Input() units: string;
  @Input() title: string;
  @Input() pointTime: string;
  @Input() pointValue: number;

  // TODO:  See above note about DER-8160 serialization.
  // This can be removed once that issue is fixed (also change reference in html file).
  uiTous: Array<UITou> = new Array<UITou>();

  constructor() {
  }

  ngOnInit(): void {
    for (let tou of this.tous) {
      this.uiTous.push(new UITou(tou));
    }
  }

}
