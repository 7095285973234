import {Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TariffService } from '../../../services/tariff.service';
import {UtilityService} from "../../../services/utility.service";
import {LoadServingEntity} from "../../../domain/load-serving-entity";
import {Router} from "@angular/router";
import {SidenavService} from "../../../services/sidenav-service";

@Component({
  selector: 'app-vtl-file-import',
  templateUrl: './vtl-file-import.component.html',
  styleUrls: ['./vtl-file-import.component.css']
})
export class VtlFileImportComponent implements OnInit {
  @Input() tariffType: string;
  tariffCode: string;
  tariffName: string;
  fileData: any;
  loading: boolean;
  lses: Array<LoadServingEntity>;
  selectedUtilityId: string;

  constructor(
    private modalService: NgbModal,
    private utilityService: UtilityService,
    private tariffService: TariffService,
    private router: Router,
    private sidenavService: SidenavService
  ) { }

  ngOnInit() {
    this.utilityService.getAllLSEs().subscribe( data => {
      data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.lses = data;
    });
  }

  modalOpen(content) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title'
      }).result.then(() => {
        this.loading = true;
        this.tariffService.importTariffVtl(this.tariffType, this.tariffCode, this.tariffName, this.selectedUtilityId, this.fileData).subscribe( (t) => {
          this.sidenavService.refreshTariffs(this.tariffType);
          this.router.navigate([`${this.tariffType.toLowerCase()}/${t.code}`]);

          this.reset();
          this.loading = false;
        }, () => {
          this.reset();
          this.loading = false;
          alert("There was a problem importing the tariff!   More details are available in the tariff-console or tariff-service logs.")
        });
    }, () => {});
  }

  onChooseFile(event: any) {
    const reader = new FileReader();
    let file = event.target.files[0];
    reader.readAsText(file);       // UTF-8 is assumed
    reader.onload = () => {
      this.fileData = reader.result.toString();
      this.tariffName = file.name.substr(0, file.name.lastIndexOf('.'));
    };
  }

  private reset() {
    // Clear form fields for next time.
    this.tariffCode = null;
    this.tariffName = null;
    this.fileData = null;
  }
}
