import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadServingEntity } from '../domain/load-serving-entity';
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class UtilityService extends BaseService {

  constructor(private http: HttpClient) {
    super(http, '');
  }

  getAllLSEs(): Observable<Array<LoadServingEntity>> {
    const options = {};
    return this.http.get<Array<LoadServingEntity>>('/utilities', options);
  }

}
