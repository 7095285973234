import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {TariffService} from './services/tariff.service';
import {TariffConfigService} from './services/tariff-config.service';
import {UtilityService} from './services/utility.service';
import {NavigationEnd, Router} from '@angular/router';
import {NgbTabChangeEvent, NgbTabset} from '@ng-bootstrap/ng-bootstrap';
import {SidenavService} from "./services/sidenav-service";
import {DeliveryType, SupplyType} from "./domain/tariff-entity";
import {MasterTariffSidenavService} from "./services/master-tariff-sidenav.service";
import {Observable, OperatorFunction} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {TariffConsoleService} from "./services/tariff-console.service";


interface SearchItem {
  text: string;
  isTariff: boolean;
  code: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('tabs', { static: true }) tabs: NgbTabset;

  title = 'DER.OS Tariff Console';
  supplyCount: number;
  deliveryCount: number;
  configurationCount: number;
  lseCount: number;
  staticTariffs: boolean;
  searchCodes: Array<SearchItem> = [];
  formatter = (x: { name: string }) => x.name;
  searchBarVisible = false;
  searchBarText = "";
  searchBoxCount: number;

  private viewName: string;

  constructor(
    private tariffService: TariffService,
    private sidenavService: SidenavService,
    private masterTariffSidenavService: MasterTariffSidenavService,
    private tariffConfigService: TariffConfigService,
    private utilityService: UtilityService,
    private tariffConsoleService: TariffConsoleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tariffConsoleService.getConfigProperties().subscribe( config => {
      this.searchBoxCount = config.searchCount;
    });
    this.tariffService.getServiceInfo().subscribe( data => {
      TariffService.isFileBased = data.isReadOnly;  // set once
      this.staticTariffs = TariffService.isFileBased;
      if (!this.staticTariffs) {
        this.masterTariffSidenavService.refresh();
        this.masterTariffSidenavService.getMasterTariffs().subscribe( data => this.configurationCount = data.length )
      }
    });

    this.sidenavService.refreshTariffs(SupplyType);
    this.sidenavService.getTariffs(SupplyType).subscribe( data => this.supplyCount = data.length );

    this.sidenavService.refreshTariffs(DeliveryType);
    this.sidenavService.getTariffs(DeliveryType).subscribe( data => this.deliveryCount = data.length );

    this.utilityService.getAllLSEs().subscribe(data => {
      this.lseCount = data.length;
    });
  }

  ngAfterViewInit() {
    this.router.events.subscribe((val) => {
      const routeConfig = this.router.config;
      if (val instanceof NavigationEnd) {
        for (const route of routeConfig) {
          if (route.path.length > 0 && val.url.includes(route.path) ) {
            this.tabs.activeId = route.path;
            this.viewName = this.tabs.activeId;
            this.loadSearchCodes(this.viewName);
          }
        }
      }
    });
  }

  onTabChange($event: NgbTabChangeEvent) {
    if ($event.nextId) {
      this.router.navigateByUrl('/' + $event.nextId).then();
    }
  }

  search: OperatorFunction<string, readonly SearchItem[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? [] : this.filterAndSortSearchResults(term))
    )

  selectItem($event, input) {
    if ($event.item) {
      console.log($event.item);
      const url = `/${this.viewName}/${$event.item.code}`;
      $event.preventDefault();
      input.value = "";
      this.router.navigateByUrl(url).then();
    }
  }

  private loadSearchCodes(tariffType: string): void {
    this.searchCodes = [];
    this.searchBarVisible = true;
    this.searchBarText = "Search for a tariff code or site name"
    let camelCaseTariffType = DeliveryType;

    switch (tariffType) {
      case 'supply':
        camelCaseTariffType = SupplyType;
        // no break -- fall through to next block
      case 'delivery':
        this.sidenavService.getTariffs(camelCaseTariffType).subscribe( data => {
          this.searchCodes = [...data.map(t => ({text: t.code, isTariff: true, code: t.code}))];
          this.loadSiteNamesAsSearchCodes(camelCaseTariffType);
        });
        break;

      case 'configurations':
        this.searchBarText = "Search for a master tariff id"
        this.masterTariffSidenavService.getMasterTariffs().subscribe( data => {
          this.searchCodes = [...data.map(t => ({text: t.code, isTariff: true, code: t.code}))];
        });
        break;
      default:
        this.searchBarVisible = false;
    }
  }

  private loadSiteNamesAsSearchCodes(tariffType): void {
    this.sidenavService.getTariffSites(tariffType).subscribe( tariffSites => {
      tariffSites.before.forEach( t => {
        t.sites.forEach(s => {
            if (!this.alreadyIn(s.name, t.code))
              this.searchCodes.push({text: s.name, isTariff: false, code: t.code})
          }
        )
      });
      tariffSites.after.forEach( t => {
        t.sites.forEach(s => {
          if (!this.alreadyIn(s.name, t.code))
            this.searchCodes.push({text: s.name, isTariff: false, code: t.code})
          }
        )
      })
      this.searchCodes.sort((a, b) => {
        return a.text.localeCompare(b.text);
      });
    });
  }

  private alreadyIn(site: string, tariff: string): boolean {
    return this.searchCodes.some(si => si.text === site && si.code === tariff)
  }

  private filterAndSortSearchResults(term: string): Array<SearchItem> {
    let items = this.searchCodes.filter(v => v.text.toLowerCase().indexOf(term.toLowerCase()) > -1)
      .slice(0, this.searchBoxCount);
    items.sort((a, b) => {
      return (+b.isTariff) - (+a.isTariff) || a.text.localeCompare(b.text);
    });
    return items;
  }

}
