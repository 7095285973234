import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FlavorMapping, UIFlavorMapping} from "../../../domain/flavor-mapping";
import {DeliveryType, TariffEntity} from "../../../domain/tariff-entity";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {GenabilityTariffProperty} from "../../../domain/genability-tariff-property";
import {ApplicabilityPair} from "../../../domain/applicability-pair";
import {BOOLEAN_CHOICES, GenabilityTariffPropertyChoice} from "../../../domain/genability-tariff-property-choice";
import {TariffConfigService} from "../../../services/tariff-config.service";
import {TariffConfig} from "../../../domain/tariff-config";
import {TariffService} from "../../../services/tariff.service";
import {SidenavService} from "../../../services/sidenav-service";
import {MasterTariffSidenavService} from "../../../services/master-tariff-sidenav.service";
import {SourceControlStatus} from "../../../domain/tariff-status";

@Component({
  selector: 'app-tariff-version',
  templateUrl: './tariff-version.component.html',
  styleUrls: ['./tariff-version.component.css']
})
export class TariffVersionComponent implements OnInit {
  @Input() masterTariff: TariffConfig;
  @Input() mapping: UIFlavorMapping;
  @Input() tariff: TariffEntity;
  @Input() adminMode: boolean;
  @Input() code: string;
  @Output() tariffCreated = new EventEmitter<FlavorMapping>();
  @Output() update = new EventEmitter<FlavorMapping>();
  @Output() updateTariffConfig = new EventEmitter<boolean>();

  public newTariffCode: string;
  public newTariffName: string;
  public unknownUtility: boolean;

  public loading = false;
  public modalLoading = false;
  public updateUnderlyingTariff: boolean = true;
  public genabilityProperties: Array<GenabilityTariffProperty> = null;
  public tempApplicabilityPairs: Array<ApplicabilityPair> = [];   // Used by the modal while editing.
  public invalidTariffMsg: string;
  public existingTariffCodes: Array<string> = [];

  constructor(private tariffConfigService: TariffConfigService,
              private tariffService: TariffService,
              private sideNavService: SidenavService,
              private masterTariffSidenavService: MasterTariffSidenavService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.sideNavService.getTariffs(DeliveryType).subscribe( data => {
      this.existingTariffCodes = data.map( t => t.code );
    })
    this.masterTariffSidenavService.getMasterTariffs().subscribe( data => {
      let sidenavMT = data.find(t => t.utilityName === this.masterTariff.utility);
      this.unknownUtility = sidenavMT ? (sidenavMT.gitStatus === SourceControlStatus.UNKNOWN) : true;
    });

  }

  createTariff(mapping: FlavorMapping, modalContent) {
    this.modalLoading = true;
    this.tariffConfigService.getGenabilityTariffCode(this.masterTariff.masterTariffId)
      .subscribe( (data) => {
        this.newTariffCode = data.code;
        this.newTariffName = data.name;
        this.modalLoading = false;
      },
        ((e) => {
          console.error(e);
          this.newTariffCode = "UNKNOWN";
          this.newTariffName = "";
          this.modalLoading = false;
        }));

    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      centered: true
    };
    this.modalService.open(modalContent, options).result.then(() => {
      this.loading = true;
      this.tariffService.createNewTariffVersion(this.masterTariff.masterTariffId, this.newTariffCode, this.newTariffName, mapping.name)
        .subscribe( xml => {
          this.tariffService.putDeliveryTariff(this.newTariffCode, xml).subscribe( () => {
            this.tariffCreated.emit(mapping);
            this.loading = false;
          })
        });
    }, () => {});

  }

  updateTariff(mapping: FlavorMapping) {
    if (confirm(`Are you sure you want to update the tariff xml for ${this.tariff.code}?`)) {
      this.loading = true;
      this.tariffService.regenerateTariffVersion(this.tariff.code, this.tariff.name)
        .subscribe( xml => {
          this.tariffService.putDeliveryTariff(this.tariff.code, xml).subscribe( () => {
            this.tariffCreated.emit(mapping);
            this.loading = false;
          })
        });
    }
  }

  edit(mapping: FlavorMapping, modalContent) {
    this.modalLoading = true;
    this.tariffConfigService.getCriteria(+this.masterTariff.masterTariffId).subscribe((properties) => {
      this.modalLoading = false;
      this.genabilityProperties = properties;
      this.tempApplicabilityPairs = mapping.applicabilityPairs.map(x => Object.assign({}, x));
    });

    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      centered: true
    };
    this.modalService.open(modalContent, options).result.then(() => {
      this.mapping.applicabilityPairs = this.tempApplicabilityPairs.map(x => Object.assign({}, x));
      this.updateTariffConfig.emit(this.updateUnderlyingTariff);
    }, () => {});
  }

  getChoices(pair: ApplicabilityPair): Array<GenabilityTariffPropertyChoice> {
    if (this.genabilityProperties !== null) {
      const prop = this.genabilityProperties.find( (p) => p.keyName === pair.applicabilityKey );
      if (prop !== null) {
        switch (prop.dataType) {
          case 'BOOLEAN':
            return BOOLEAN_CHOICES;
          case 'CHOICE':
            return prop.choices;
          default:
            return [];    // According to DER-8343, only BOOLEAN and CHOICE need to be supported
        }
      }
    }
    return [];
  }

  isTariffCodeTaken(): boolean {
    return this.existingTariffCodes.indexOf(this.newTariffCode) > -1;
  }
}
