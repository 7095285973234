import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LoadServingEntity } from '../../../domain/load-serving-entity';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-lses-view',
  templateUrl: './lses-view.component.html',
  styleUrls: ['./lses-view.component.css']
})
export class LsesViewComponent implements OnInit {

  lses: Array<LoadServingEntity>;
  selectedLSE: LoadServingEntity = undefined;
  loading: boolean;

  constructor(
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loading = true;

    this.utilityService.getAllLSEs().subscribe(data => {
      data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.lses = data;

      this.route.paramMap.subscribe((params: ParamMap) => {
        const id = params.get('id');
        if (id !== null) {
          for (const lse of this.lses) {
            if (lse.id === id) {
              this.selectedLSE = lse;
            }
          }
        }
      });
      this.loading = false;
    });
  }
}
