import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

import { TariffConfig } from '../domain/tariff-config';
import { TariffConfigRates } from '../domain/tariff-config-rates';
import { prepareFlavorMappingsForPUT } from '../domain/flavor-mapping';
import {BaseService} from "./base.service";
import {TariffEntity} from "../domain/tariff-entity";
import {GenabilityTariffProperty} from "../domain/genability-tariff-property";
import {TariffBase} from "../domain/tariff-base";

@Injectable({
  providedIn: 'root'
})
export class TariffConfigService extends BaseService {

  constructor(private http: HttpClient) {
    super(http, "");
  }

  private readonly rootPath = '/master-tariffs';

  getAllTariffConfigs(): Observable<Array<TariffConfig>> {
    const options = {};
    return this.http.get<Array<TariffConfig>>( this.rootPath, options);
  }

  getTariffConfig(id: number): Observable<TariffConfig> {
    const options = {};
    const url =  `${this.rootPath}/${id}`;
    return this.http.get<TariffConfig> (url, options);
  }

  getTariffsForConfig(id: number): Observable<Array<TariffEntity>> {
    const options = {};
    const url =  `${this.rootPath}/${id}/tariffs/delivery`;
    return this.http.get<Array<TariffEntity>> (url, options);
  }

  deleteTariffConfig(cfg: TariffConfig): Observable<TariffConfig> {
    const options = {};
    const url =  `${this.rootPath}/${cfg.masterTariffId}`;
    return this.http.delete<TariffConfig> (url, options);
  }

  updateTariffConfig(cfg: TariffConfig): Observable<TariffConfig> {
    cfg.versions = prepareFlavorMappingsForPUT( cfg.versions );
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const url =  `${this.rootPath}/${cfg.masterTariffId}`;
    return this.http.put<TariffConfig> (url, cfg, options);
  }

  updateTariffConfigRaw(masterTariffId: number, json: string): Observable<TariffConfig> {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const url =  `${this.rootPath}/${masterTariffId}`;
    return this.http.put<TariffConfig> (url, json, options);
  }


  importNewMasterTariff(id: number): Observable<TariffConfig> {
    const options = {};
    const url = `${this.rootPath}/new/${id}`;
    return this.http.get<TariffConfig>( url, options);
  }


  getTariffRates(id: number): Observable<TariffConfigRates> {
    return this.http.get<TariffConfigRates>( `${this.rootPath}/${id}/rates`, {});
  }

  getCriteria(id: number):  Observable<Array<GenabilityTariffProperty>> {
    return this.http.get<Array<GenabilityTariffProperty>>(`${this.rootPath}/${id}/criteria`, {});
  }

  getVtl(id: number, year: number): Observable<string> {
    return this.http.get(`${this.rootPath}/${id}/vtl?year=${year}`, {responseType: 'text'});
  }

  getGenabilityTariffCode(id: number): Observable<TariffBase> {
    return this.http.get<TariffBase>( `${this.rootPath}/${id}/summary`, {});
  }

}
