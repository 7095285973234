import {ApplicabilityPair} from './applicability-pair';

export interface FlavorMapping {
  name: string;
  applicabilityPairs: Array<ApplicabilityPair>;
}

export interface UIFlavorMapping extends FlavorMapping {
  code: string;     // UI-only field.  Will not be in REST data.
}

/**
 * Removes any 'code' attributes added via UIFlavorMapping.  This is required before doing a PUT on
 * the tariff config.  Otherwise the server will throw an exception.
 * See additional info in {@link TariffConfigsViewComponent.matchVersionsToTariffs}.
 */
export function prepareFlavorMappingsForPUT(arr: Array<FlavorMapping>): Array<FlavorMapping> {
  const cleanedMappings: Array<FlavorMapping> = [];
  for (const mapping of arr) {
    cleanedMappings.push( {
      name: mapping.name,
      applicabilityPairs: mapping.applicabilityPairs
    });
  }
  return cleanedMappings;
}
