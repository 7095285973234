import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TariffBase} from "../../../domain/tariff-base";
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {UtilityService} from "../../../services/utility.service";
import {SidenavService} from "../../../services/sidenav-service";

interface UtilityPair {
  id: string;
  name: string;
}

@Component({
  selector: 'app-tariff-sidenav',
  templateUrl: './tariff-sidenav.component.html',
  styleUrls: ['./tariff-sidenav.component.css']
})
export class TariffSidenavComponent implements OnInit, OnChanges {
  @Input() tariffs: Array<SidenavTariff>;
  @Input() tariffType: string;
  @Input() selectedTariff: TariffBase;
  @Output() selectedTariffChange: EventEmitter<TariffBase> = new EventEmitter<TariffBase>();
  @Input() expandAll: boolean;
  public hideUnusedTariffs: boolean;

  tariffMap: Map<string, Array<SidenavTariff>>;
  utilities: Array<UtilityPair>;

  constructor(private utilityService: UtilityService,
              private sidenavService: SidenavService) { }

  ngOnInit() {}

  ngOnChanges() {
    if (this.tariffs.length > 0) {
      this.sidenavService.getHideUnused(this.tariffType).subscribe(b => this.hideUnusedTariffs = b);

      this.utilityService.getAllLSEs().subscribe( (lses) => {
        this.utilities = [];

        // Add the known LSEs.
        for (const lse of lses) {
          this.utilities.push( { id: lse.id, name: lse.name });
        }

        // Add any utilities that are in use but not yet in the known LSEs.
        for (let t of this.tariffs) {
          if (!this.utilities.find( u => u.id === t.utilityId)) {
            console.warn(`Unknown utility [${t.utilityId}]="${t.utilityName}"`);
            this.utilities.push({ id: t.utilityId, name: t.utilityName});
          }
        }

        // Sort and create the map of tariffs per utility.
        this.utilities.sort((a, b) => {
          return a.name ? a.name.localeCompare(b.name) : -1;
        });
        this.tariffMap = new Map<string, Array<SidenavTariff>>();
        this.utilities.forEach( u => {
          this.tariffMap.set( u.id, this.tariffs.filter(t => {
            return t.utilityId === u.id || (u.id === null && t.utilityId === "");
          }) );
        })
      });
    }
  }

  refreshList() {
    console.log(`Refreshing ${this.tariffType} tariffs, ${this.hideUnusedTariffs ? "filtered" : "unfiltered"}...`)
    this.sidenavService.refreshTariffs(this.tariffType, this.hideUnusedTariffs);
  }

}
