import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FlavorMapping, UIFlavorMapping} from '../../../domain/flavor-mapping';
import {TariffEntity} from '../../../domain/tariff-entity';
import {AdminService} from '../../../services/admin.service';
import {TariffConfig} from '../../../domain/tariff-config';
import {TariffConfigService} from '../../../services/tariff-config.service';


@Component({
  selector: 'app-tariff-version-list',
  templateUrl: './tariff-version-list.component.html',
  styleUrls: ['./tariff-version-list.component.css']
})
export class TariffVersionListComponent implements OnInit, OnChanges {

  @Input() config: TariffConfig;
  @Input() tariffs: Array<TariffEntity>;
  @Output() tariffCreated = new EventEmitter<string>();
  @Output() updateTariffVersion = new EventEmitter<TariffEntity>();

  unflavoredTariffs: Array<TariffEntity>;
  codifiedVersions: Array<UIFlavorMapping>;
  navbarCollapsed = true;
  loading = false;

  constructor(public admin: AdminService,
              private tariffConfigService: TariffConfigService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.navbarCollapsed = false;

    // Find the versions that have a corresponding tariff.
    for (const flavor of this.config.versions ) {
      const version = this.tariffs.find( t => t.options === flavor.name);
      (<UIFlavorMapping> flavor).code = version ? version.code : null;
    }

    // Find the tariffs that have no corresponding version.
    this.unflavoredTariffs = [];
    for (const tariff of this.tariffs) {
      if (this.config.versions.find(m => (<UIFlavorMapping> m).code === tariff.code)) {
        continue;
      }
      this.unflavoredTariffs.push(tariff);
    }
    // The only reason we need this is because the "as" operator isn't supported in html templates.
    this.codifiedVersions = this.config.versions as Array<UIFlavorMapping>;
    this.codifiedVersions.sort((a, b) => {
      return a.code === null ? 1 : a.code.localeCompare(b.code);
    });
  }

  toggleCollapsed() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  getTariff(mapping: FlavorMapping): TariffEntity {
    for (const tariff of this.tariffs) {
      if (tariff.code === (<UIFlavorMapping> mapping).code) {
        return tariff;
      }
    }
    return null;
  }

  onTariffCreated(mapping: FlavorMapping) {
    this.tariffCreated.emit(mapping.name);
  }


  onUpdateTariffConfig($event, mapping: UIFlavorMapping) {
    const updateTariff: boolean = $event.valueOf();
    this.tariffConfigService.updateTariffConfig(this.config).subscribe(() => {
      if (updateTariff) {
        this.updateTariffVersion.emit(this.getTariff(mapping));
      }
    });
  }
}
