import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';

import { DeliveryTariffsViewComponent } from './delivery-tariffs-view/delivery-tariffs-view.component';
import { DeliveryTariffComponent } from './delivery-tariff/delivery-tariff.component';
import { DeliveryTariffListComponent } from './delivery-tariff-list/delivery-tariff-list.component';
import { TariffBillingComponent } from "./tariff-billing/tariff-billing.component";

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChargingStation, faChartLine, faList, faDollarSign } from '@fortawesome/free-solid-svg-icons';

library.add(faChargingStation, faChartLine, faList, faDollarSign);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    DeliveryTariffComponent,
    DeliveryTariffsViewComponent,
    DeliveryTariffListComponent,
    TariffBillingComponent
  ],
  exports: [
    DeliveryTariffListComponent,
    DeliveryTariffsViewComponent
  ]
})
export class DeliveryTariffsModule { }
