import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

import {SupplyTariffComponent} from './supply-tariff/supply-tariff.component';
import {SupplyTariffsViewComponent} from './supply-tariffs-view/supply-tariffs-view.component';
import {SupplyTariffListComponent} from './supply-tariff-list/supply-tariff-list.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faList, faChartLine } from '@fortawesome/free-solid-svg-icons';

library.add(faList, faChartLine);

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    SupplyTariffListComponent,
    SupplyTariffComponent,
    SupplyTariffsViewComponent
  ],
  exports: [
    SupplyTariffsViewComponent,
  ]
})
export class SupplyTariffsModule { }
