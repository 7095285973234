import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { LseListComponent } from './lse-list/lse-list.component';
import { LsesViewComponent } from './lses-view/lses-view.component';
import { LseViewComponent } from './lse-view/lse-view.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faTrash);

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FontAwesomeModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    LseListComponent,
    LseViewComponent,
    LsesViewComponent
  ],
  exports: [
    LsesViewComponent
  ]
})
export class LsesModule { }
