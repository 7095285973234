import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { ConfigurationsModule } from './components/configurations/configurations.module';
import { SharedModule } from './components/shared/shared.module';

import { TariffService } from './services/tariff.service';
import { TariffConfigService } from './services/tariff-config.service';
import { AdminService } from './services/admin.service';
import { DeliveryTariffsModule } from './components/delivery/delivery-tariffs.module';
import { SupplyTariffsModule } from './components/supply/supply-tariffs.module';
import { LsesModule } from './components/lses/lses.module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt, faWarehouse } from '@fortawesome/free-solid-svg-icons';

library.add(faFileAlt, faWarehouse);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    ConfigurationsModule,
    SharedModule,
    DeliveryTariffsModule,
    SupplyTariffsModule,
    LsesModule
  ],
  providers: [
    TariffService,
    TariffConfigService,
    AdminService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
