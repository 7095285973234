import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {
  @Input() startDate;
  @Input() endDate;

  hovered: NgbDate;
  from: NgbDate;
  to: NgbDate;
  time: NgbTimeStruct;
  timeCollapsed: boolean;

  @ViewChild('content', { static: true }) modalContent;

  constructor() { }

  ngOnInit() {
    const m1 = moment(this.startDate);
    const m2 = moment(this.endDate);
    this.from = new NgbDate( m1.year(), m1.month() + 1, m1.date() );
    this.to = new NgbDate( m2.year(), m2.month() + 1, m2.date() );
    this.time = { hour: 0, minute: 0, second: 0 };
    this.timeCollapsed = true;
  }

  onDateSelection(date: NgbDate) {
    if (!this.from && !this.to) {
      this.from = date;
    } else if (this.from && !this.to && date.after(this.from)) {
      this.to = date;
    } else {
      this.to = null;
      this.from = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.from &&
      !this.to &&
      this.hovered &&
      date.after(this.from) &&
      date.before(this.hovered);
  }

  isInside(date: NgbDate) {
    return date.after(this.from) && date.before(this.to);
  }

  isRange(date: NgbDate) {
    return date.equals(this.from) ||
      date.equals(this.to) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

}
