import {Component, Input, OnInit} from "@angular/core";
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {SidenavService} from "../../../services/sidenav-service";
import {DeliveryType, SupplyType} from "../../../domain/tariff-entity";
import {TariffSiteList} from "../../../domain/tariff-site-lists";

interface BeforeAfter {
  before: boolean;
  after: boolean;
}

@Component({
  selector: 'app-tariff-sites',
  templateUrl: './tariff-sites.component.html',
  styleUrls: ['./tariff-sites.component.css']
})
export class TariffSitesComponent  implements OnInit {
  @Input() tariff: SidenavTariff;
  @Input() tariffType: string;

  loading: boolean = true;
  beforeCount: number = 0;
  afterCount: number = 0;
  siteRows: Map<string, BeforeAfter> = new Map<string, BeforeAfter>();
  siteKeys: Array<string>;

  constructor(public sidenavService: SidenavService) {}

  ngOnInit(): void {
    // HACK!!!  We inconsistently use tariffType in supply-tariff-list-component.html
    // and delivery-tariff-list-component.html, both of which use lowercase versions instead of DeliveryType/SupplyType.
    // TODO:  Fix those instead, but doing it here to minimize risk.
    const tt = this.tariffType.toLowerCase() === DeliveryType.toLowerCase() ? DeliveryType : SupplyType;

    this.sidenavService.getTariffSites(tt).subscribe(tsl => {
      const sitesBefore: TariffSiteList = tsl.before.find(t => t.code === this.tariff.code);
      const sitesAfter: TariffSiteList = tsl.after.find(t => t.code === this.tariff.code);
      const beforeSiteNames = sitesBefore ? sitesBefore.sites.map(s => s.name) : [];
      const afterSiteNames = sitesAfter ? sitesAfter.sites.map(s => s.name) : [];
      this.beforeCount = beforeSiteNames.length;
      this.afterCount = afterSiteNames.length;

      const allSites = Array.from(new Set([...beforeSiteNames,...afterSiteNames]));
      allSites.sort((a, b) => {
        return a.localeCompare(b);
      });
      for (let s of allSites) {
        this.siteRows.set(s, {
          before: beforeSiteNames.includes(s),
          after: afterSiteNames.includes(s)
        })
      }
      this.siteKeys = Array.from(this.siteRows.keys());

      this.loading = false;
    });
  }

}
