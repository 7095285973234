import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SupplyTariff } from '../domain/supply-tariff';
import { DeliveryTariff } from '../domain/delivery-tariff';
import {TariffEntity} from '../domain/tariff-entity';
import {RateSeries} from '../domain/rate-series';
import {Tou} from "../domain/tou";
import {BaseService} from "./base.service";
import {TariffStatus} from "../domain/tariff-status";
import {TariffBase} from "../domain/tariff-base";
import {TariffSiteLists} from "../domain/tariff-site-lists";

@Injectable({
  providedIn: 'root'
})
export class TariffService extends BaseService {

  static prettify<T extends TariffBase>(arr: Array<T>): Array<T> {
    arr.sort( (a, b) => {
      const utilComp = a.utilityName ? (b.utilityName ? a.utilityName.localeCompare(b.utilityName) : -1) : 1;
      const nameComp = a.name.localeCompare(b.name);
      return utilComp || nameComp;
    } );
    return arr;
  }

  static cronReadable(cron: string): string {
    // Poor man's cron-expression evaluator for cadence strings currently used.
    // Later this could be expanded to use something like https://www.npmjs.com/package/cronstrue
    switch (cron) {
      case null :
        return '(Default)';
      case '0 0/15 * * * ?':
        return '15 mins';
      case '0 0/5 * * * ?':
        return '5 mins';
      default:
        return cron;
    }
  }

  public static isFileBased = undefined;


  constructor(private http: HttpClient) {
    super(http, "");
  }

  getTariffsSourceControlStatus(): Observable<Array<TariffStatus>> {
    const options = {};
    return this.http.get<Array<TariffStatus>>( '/tariffs/status', options );
  }

  getTariffSourceControlStatus(code: string): Observable<TariffStatus> {
    const options = {};
    return this.http.get<TariffStatus>( `/tariffs/status/${code}/`, options );
  }


  promoteTariff(code: string, commitMessage: string): Observable<TariffStatus> {
    let params = new HttpParams();
    params = params.set('message', commitMessage);
    const options = { params: params };
    return this.http.get<TariffStatus>( `/tariffs/status/${code}/promoted`, options);
  }

  revertTariff(code: string): Observable<TariffStatus> {
    const options = {};
    return this.http.get<TariffStatus>( `/tariffs/status/${code}/reverted`, options);
  }


  getTariffs(tariffType: string): Observable<Array<TariffEntity>> {
    const options = {};
    return this.http.get<Array<TariffEntity>>( `/tariffs/${tariffType.toLocaleLowerCase()}`, options );
  }


  getSupplyTariff(code: string): Observable<SupplyTariff> {
    const options = {};
    return this.http.get<SupplyTariff>( '/tariffs/supply/' + code, options);
  }

  getDeliveryTariff(code: string): Observable<DeliveryTariff> {
    const options = {};
    return this.http.get<DeliveryTariff>( '/tariffs/delivery/' + code, options);
  }

  putDeliveryTariff(code: string, xml: string): Observable<DeliveryTariff> {
    const httpOptions = {
      headers: { 'Content-Type': 'application/xml' }
    };
    const url = `/tariffs/delivery/xml/${code}`;
    return this.http.put<DeliveryTariff>( url, xml, httpOptions);
  }

  getTariffXml(code: string): Observable<string> {
    return this.http.get( '/tariffs/xml/' + code, {responseType: 'text'});
  }

  deleteTariffXml(code: string): Observable<void> {
    return this.http.delete<void>( '/tariffs/' + code);
  }

  createNewTariffVersion(masterTariffId: number, code: string, name: string, options: string): Observable<string> {
    const url = `/tariffs/delivery/new/?masterTariffId=${masterTariffId}&code=${code}&name=${name}&options=${options}`;
    return this.http.get( url, {responseType: 'text'});
  }

  regenerateTariffVersion(code: string, name: string): Observable<string> {
    const url = `/tariffs/delivery/update/${code}?name=${name}`;
    return this.http.get( url, {responseType: 'text'});
  }

  importTariffVtl(tariffType: string, code: string, name: string, utilityId: string, body: string): Observable<TariffEntity> {
    let params = new HttpParams();
    params = params.set('code', code);
    params = params.set('name', name);
    if (utilityId) {
      params = params.set('utilityId', utilityId);
    }
    const httpOptions = {
      params: params,
      headers: { 'Content-Type': 'text/plain' }
    };
    const url = `/tariffs/${tariffType.toLowerCase()}/vtl/${code}`;
    return this.http.put<TariffEntity>( url, body, httpOptions);
  }

  getConsumption(delivery: string,
                 supply: string,
                 fromDate: string,
                 toDate: string,
                 pointStrategy: boolean): Observable<Array<RateSeries>> {
    let params = new HttpParams();
    params = params.set('deliveryCode', delivery);
    params = params.set('supplyCode', supply);
    params = params.set('startDate', fromDate);
    params = params.set('stopDate', toDate);

    if (pointStrategy !== undefined) {
      params = params.set('endStamp', '' + pointStrategy);
    }

    const httpOptions = {
      params: params
    };

    return this.http.get<any>(`/rates/consumption`, httpOptions);
  }

  getDemand(deliveryCode: string, startDate: string, stopDate: string, endStamp: boolean, billingPeriod: string): Observable<Array<RateSeries>> {
    let params = new HttpParams();
    params = params.set('deliveryCode', deliveryCode);
    params = params.set('startDate', startDate);
    params = params.set('stopDate', stopDate);
    params = params.set('endStamp', endStamp ? 'true' : 'false');
    if (billingPeriod) {
      params = params.set('billingPeriods', billingPeriod);
    }

    const httpOptions = {
      params: params
    };

    return this.http.get<any>(`/rates/demand`, httpOptions);
  }



  getConsumptionTous(deliveryCode: string, supplyCode: string, pointDateTime: string): Observable<Array<Tou>> {
    let params = new HttpParams();
    params = params.set('deliveryCode', deliveryCode);
    params = params.set('supplyCode', supplyCode);
    params = params.set('dateTime', pointDateTime);
    const httpOptions = {
      params: params
    };

    return this.http.get<Array<Tou>>(`/rates/consumption/tous`, httpOptions);
  }

  getDemandTous(deliveryCode: string, pointDateTime: string): Observable<Array<Tou>> {
    let params = new HttpParams();
    params = params.set('deliveryCode', deliveryCode);
    params = params.set('dateTime', pointDateTime);
    const httpOptions = {
      params: params
    };

    return this.http.get<Array<Tou>>(`/rates/demand/tous`, httpOptions);
  }

  getTariffSites(tarifType: string): Observable<TariffSiteLists> {
    const options = {};
    return this.http.get<TariffSiteLists>( `/tariffs/${tarifType.toLowerCase()}/sites`, options);
  }
}

