import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import { TariffService } from '../../../services/tariff.service';
import { UtilityService } from '../../../services/utility.service';
import {MasterTariffSidenavService} from "../../../services/master-tariff-sidenav.service";
import {SidenavTariff} from "../../../domain/sidenav-tariff";
import {Observable} from "rxjs";
import {LoadServingEntity} from "../../../domain/load-serving-entity";

@Component({
  selector: 'app-tariff-configs-view',
  templateUrl: './tariff-configs-view.component.html',
  styleUrls: ['./tariff-configs-view.component.css']
})
export class TariffConfigsViewComponent implements OnInit, OnDestroy {
  configs: Array<SidenavTariff>;
  loading: Observable<boolean>;
  selectedTariff: SidenavTariff;
  navigationSubscription;
  lses: Array<LoadServingEntity>;

  constructor(
    private sidenavService: MasterTariffSidenavService,
    private tariffService: TariffService,
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loading = this.sidenavService.isLoading();
    this.sidenavService.getMasterTariffs().subscribe( data => {
      this.configs = data;

      this.utilityService.getAllLSEs().subscribe( lses => {
        this.lses = lses;
        this.route.paramMap.subscribe((params: ParamMap) => {
          const id = params.get('id');
          for (const config of this.configs) {
            if (config.code === id) {
              this.selectedTariff = config;
            }
          }
          this.loading = this.sidenavService.isLoading();
        });
      });
    });
  }

  ngOnDestroy(): void {
    // Important!  Unsubscribe from the navigation or we will continue to get it after leaving this view.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

}
