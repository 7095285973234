import { Component, Input, OnInit } from '@angular/core';
import { SimpleMapping } from '../../../domain/simple-mapping';


@Component({
  selector: 'app-simple-mapping-list',
  templateUrl: './simple-mapping-list.component.html',
  styleUrls: ['./simple-mapping-list.component.css']
})
export class SimpleMappingListComponent implements OnInit {

  @Input() mappings: Array<SimpleMapping>;
  @Input() title: string;
  navbarCollapsed = true;

  constructor() { }

  ngOnInit() {
    this.navbarCollapsed = true;
  }

  toggleCollapsed() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }
}
